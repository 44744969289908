import * as THREE from "three";
import vShader from './shaders/vertexShader';
import fShader from './shaders/fragmentShader';
import { prng_alea } from 'esm-seedrandom';
import colorCombos from './colorCombos'


export default {
  components: {
  },
  props: [
  ],
  data: function () {
    this.previewCamera = null;
    this.previewScene = null;
    this.previewRenderer = null;
    this.previewMesh = null;
    return {
      stariness: 4,
      scale: 1,
      lineFactor: 2.0,
      showImages: false,
      solidStars: false,
      thresholdStars: true,
      groupId: 1,
      numId: 1,
      colors: [
        0x8c33f2,
        0x38cff2,
        0xf23652,
        0x430dba,
        0xff36ee,
        0x4583ff,
        0xff4527,
        0xff3b83,
        0x001fff,
        0xff0068,
        0x00b1ff,
        0x5d55ff
      ]
    }
  },
  mounted() {
  },
  created() {
  },
  computed: {
    previewWidth: function () {
      return document.getElementById('graph-preview').offsetWidth;
    },
    previewHeight: function () {
      return document.getElementById('graph-preview').offsetHeight;
    },
    totalNodes: function () {
      return this.$store.state.graphData.nodes.length;
    }
  },
  methods: {
    onResize: function () {
      let el = document.getElementById('graph-preview');
      console.log("WIDTH: " + el.offsetWidth);
      // console.log("HEIGHT: " + el.offsetHeight);

      this.previewWidth = el.offsetWidth;
      this.previewHeight = el.offsetHeight;

      this.previewRenderer.setSize(this.previewWidth, this.previewWidth);
    },
    initMesh: function () {
      var collectionIdx = parseFloat(this.collectionVal) + 2
      var typeIdx = parseFloat(this.typeVal);

      var nmd = parseFloat(this.numId);
      const textureImage = require('./textures/test_texture.png');
      const imgTexture = new THREE.TextureLoader().load(textureImage)
      let myrng = prng_alea(String(typeIdx * 100));

      var tuniforms = {
        time: { type: "f", value: 1.0 },
        stariness: { type: "f", value: this.stariness },
        showImages: { type: "b", value: this.showImages },
        solidStars: { type: "b", value: this.solidStars },
        thresholdStars: { type: "b", value: this.thresholdStars },
        tNodes: { type: "f", value: this.totalNodes },
        lineFactor: { type: "f", value: this.lineFactor },
        numId: { type: "f", value: nmd },
        m: { type: "f", value: collectionIdx },
        n1: { type: "f", value: collectionIdx },
        n2: { type: "f", value: nmd },
        typeId: { type: "f", value: typeIdx },
        image: { type: "t", value: imgTexture },
        opacity: { type: "f", value: 1.0 },
        typeRandom: { type: "f", value: myrng() },
        typeColor: { type: "vec3", value: new THREE.Color(colorCombos[typeIdx][0]) },
        highlightColor: { type: "vec3", value: new THREE.Color(colorCombos[typeIdx][1]) },
        secondaryHighlightColor: { type: "vec3", value: new THREE.Color(colorCombos[typeIdx][2]) },
        collectionColor: { type: "vec3", value: new THREE.Color(this.colors[collectionIdx]) }
      }

      var tdefines = {
        PI: 3.1415926,
      };

      var superShaderMaterial = new THREE.ShaderMaterial({
        uniforms: tuniforms,
        defines: tdefines,
        vertexShader: vShader,
        fragmentShader: fShader,
        side: THREE.DoubleSide,
        transparent: true,
      });

      var planeWidth = 2.0;
      this.previewMesh = new THREE.Mesh(new THREE.PlaneGeometry(planeWidth, planeWidth), superShaderMaterial);
      this.previewMesh.name = "preview";

      // this.previewMesh.renderOrder = 999;
      // this.previewMesh.material.depthTest = false;
      // this.previewMesh.material.depthWrite = false;
    },

    initPreview: function () {
      let container = document.getElementById("graph-preview");

      this.previewCamera = new THREE.OrthographicCamera(
        -1, // left
        1,  // right
        1,  // top
        -1, // bottom
        -1, // near,
        1,  // far
      );

      this.previewScene = new THREE.Scene();

      // let geometry = new THREE.BoxGeometry(1, 0.2, 0.2);
      // let material = new THREE.MeshNormalMaterial();
      // this.previewMesh = new THREE.Mesh(geometry, material);


      this.initMesh();
      // console.log(this.previewMesh);

      this.previewScene.add(this.previewMesh);

      this.previewRenderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });

      this.previewRenderer.setSize(this.previewWidth, this.previewWidth);


      // this.previewScene.background = new THREE.Color("rgba(0, 0, 0)");
      this.previewRenderer.setClearColor(0xffffff, 0.0);

      container.appendChild(this.previewRenderer.domElement);

    },
    animate: function () {
      requestAnimationFrame(this.animate);
      this.previewMesh.rotation.x += 0.005;
      this.previewMesh.rotation.y += 0.005;
      this.previewRenderer.render(this.previewScene, this.previewCamera);
    },
  },
  watch: {
    typeVal: function (val) {
      this.previewMesh.material.uniforms.typeId.value = val;

      this.previewMesh.material.uniforms.typeColor.value = new THREE.Color(colorCombos[val][0]);

      this.previewMesh.material.uniforms.highlightColor.value = new THREE.Color(colorCombos[val][1]);

      this.previewMesh.material.uniforms.secondaryHighlightColor.value = new THREE.Color(colorCombos[val][2]);

    },
    collectionVal: function (val) {
      this.previewMesh.material.uniforms.m.value = val + 2;
      this.previewMesh.material.uniforms.n1.value = val + 2;
      // this.previewMesh.material.uniforms.lineFactor.value = val + 1.0;
    }
  }
}
