<template>
  <div
    v-if="showAbout"
    class="container-fluid p-4 ps-0 pb-0 pt-2 m-3"
    :style="{
      top: navbarHeight + 'px',
    }"
  >
    <div
      class="col-lg- col-md-6 col-sm-6 node-card m-2 p-1 pe-3"
      :style="{
        maxHeight: '70vh',
      }"
    >
      <div class="node-card-inner mt-3 mb-3 pe-3">
        <div class="row d-flex justify-content-between m-2 mt-3 mb-4">
          <div class="col-8">
            <h1>Tracing Queerness: Archiving the Ephemeral</h1>
          </div>
        </div>

        <!-- <div class="row m-2 mt-1 mb-5">
          <div class="col-8">
            <h5>
              An exhibition at the Keller Gallery<br />MIT School of
              Architecture and Planning
            </h5>
          </div>
        </div> -->

        <div class="row pe-3 m-2 mt-2 mb-5">
          <div class="col-10">
            <h3>
              This exhibit proposes an alternate mode of assembling a
              community-driven queer space to ask: how do queer spatial
              practices simultaneously orient the queer community and dis-orient
              familiar ways of knowing self, space, and time? Tracing Queerness
              offers a glimpse into this ongoing, contingent process of tracing
              the worlds we inherit and disinherit through contributions from a
              vast and growing community of artists, scholars, party-goers,
              activists, historians, and architects, among others, building up
              an inclusive and expanded notion of citation that bridges between
              the social, the spatial, and the academic. The exhibit also
              invites visitors to contribute something as kin and become part of
              an expansive and ephemeral set of relations – neither entirely
              digital nor physical – in order to be transformed by the spaces
              they have shaped and reimagined.
            </h3>
          </div>
        </div>

        <div class="row m-2 mt-2 mb-4">
          <div class="col-6">
            <h4>
              Tracing Queerness is supported by the Department of Architecture
              at MIT. Special thanks to Merve Akdogan, J. Yolande Daniels,
              Nicholas de Monchaux, Amanda Moore, Doug Le Vie, Jackie Qianyue
              Xu, and to all the staff, scholars, students, developers,
              designers and thinkers in the Queer Space Lab.
            </h4>
          </div>

          <div class="col-6">
            <!-- CURATORS -->
            <div class="row pe-3 m-2 mt-0 mb-0">
              <h5>Curated by</h5>
            </div>
            <div class="row pe-3 m-2 mb-0 mt-0">
              <h4 class="tag">
                <template v-for="(curator, index) in curators" :key="curator">
                  {{ curator }}
                  <span v-if="index != curators.length - 1"> &#9679; </span>
                </template>
              </h4>
            </div>

            <!-- DEVELOPERS -->
            <div class="row pe-3 m-2 mt-2 mb-0">
              <h5>Site development by</h5>
            </div>
            <div class="row pe-3 m-2 mb-0 mt-0">
              <h4 class="tag">
                <template
                  v-for="(developer, index) in developers"
                  :key="developer"
                >
                  {{ developer }}
                  <span v-if="index != developers.length - 1"> &#9679; </span>
                </template>
              </h4>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="row ps-3 mt-5">
        <div class="col-3 ps-3">
            <h2>{{selectedNode.date}}</h2>
        </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "AboutCard",
  props: [],
  mixins: [],
  data: function () {
    return {
      name: "test",
      width: window.innerWidth,
      height: window.innerHeight,
      curators: ["Aidan Flynn", "J. Jih"],
      developers: ["Hybrid Objects", "Gil Sunshine"],
    };
  },
  methods: {
    clearSelectedNode: function () {},
  },
  mounted: function () {
    // console.log(window.innerHeight);
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  unmounted() {
    window.removeEventListener("resize", this.onResize);
  },
  computed: {
    selectedNode() {
      return this.$store.state.selectedNode;
    },
    showAbout() {
      return this.$store.state.showAbout;
    },
    cssProps: function () {
      return {
        "--width": window.innerWidth,
        "--height": window.innerHeight,
      };
    },
    navbarHeight: function () {
      return this.$store.state.navbarHeight;
    },
  },
  watch: {
    // selectedNode
    selectedNode: function (val) {
      if (val == null) {
        this.isVisible = false;
        return;
      }

      this.isVisible = true;

      this.selectedNode = val;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.container-fluid {
  position: absolute;

  left: 0px;

  color: white;

  z-index: 50000;
  pointer-events: none;
}

.node-card {
  pointer-events: all !important;
  backdrop-filter: blur(20px);

  // border: 1px white solid;
  box-shadow: 0 0 0px 1px white;

  margin: 10px;

  border-radius: 20px;

  padding-bottom: 0px;

  max-height: 95%;
  overflow: hidden;

  display: flex;
  flex-direction: column;
}

.node-card-inner {
  overflow-y: auto;
}

.node-card h1 {
  font-size: 1.953rem !important;

  text-transform: uppercase;

  line-height: 100%;
}

.node-card h3 {
  line-height: 108%;
}

.tag {
  // text-transform: uppercase;
}

.node-card button {
  white-space: nowrap !important;
}

/* width */
::-webkit-scrollbar {
  width: 1px;
  height: 90% !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  height: 90% !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(255, 255, 255);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(255, 255, 255);
}
</style>
