<template>
  <div>
    <vue3-slider
      v-model="sliderVal"
      color="#FFFFFF"
      height="3"
      :min=minVal
      :max=maxVal
      alwaysShowHandle="true"
      handleScale="2"
      track-color="#000000"
      @change="onSliderChange()"
    />
  </div>
</template>

<script>
import slider from "vue3-slider";

export default {
  name: "Slider",
  props: { 
    minVal: Number,
    maxVal: Number
    },
  mixins: [],
  components: {
    "vue3-slider": slider,
  },
  data: function () {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
      slider: null,
      sliderVal: Math.random() * 2,
    };
  },
  methods: {
    onToggleTypes() {},
    onSliderChange() {
    //   console.log(this.sliderVal);
      this.$emit('onSliderChange', this.sliderVal);
    },
  },
  mounted: function () {},
  created() {
    window.addEventListener("resize", this.onResize);
  },
  unmounted() {
    window.removeEventListener("resize", this.onResize);
  },
  computed: {
    cssProps: function () {
      return {
        "--width": window.innerWidth,
        "--height": window.innerHeight,
      };
    },
  },
  watch: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
#ex1Slider .slider-selection {
  background: #bababa;
}
</style>