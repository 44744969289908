import * as THREE from "three";
import { Line2, LineGeometry, LineMaterial } from 'three-fatline';
import ForceGraph3D from '3d-force-graph';
import vShader from './shaders/vertexShader';
import fShader from './shaders/fragmentShader';
// import * as dat from 'dat.gui';
import {prng_alea} from 'esm-seedrandom'
import colorCombos from './colorCombos'
// import {toRaw } from 'vue';

var currentNode;
var currentNodePos;
var totalNodes;
var spin = false
var angle = 0

const controls =
{
  stariness: 2,
  scale: 1,
  showImages: false,
  solidStars: true,
  thresholdStars: false
}

// function easeOutCubic(num) {
// return 1 - Math.pow(1 - num, 3);
// }
//
// function cartesian(...args) {
//     var r = [], max = args.length-1;
//     function helper(arr, i) {
//         for (var j=0, l=args[i].length; j<l; j++) {
//             var a = arr.slice(0); // clone arr
//             a.push(args[i][j]);
//             if (i==max)
//                 r.push(a);
//             else
//                 helper(a, i+1);
//         }
//     }
//     helper([], 0);
//     return r;
// }
//
// function shuffle(array) {
//   let currentIndex = array.length,  randomIndex;
//
//   // While there remain elements to shuffle.
//   while (currentIndex != 0) {
//
//     // Pick a remaining element.
//     randomIndex = Math.floor(Math.random() * currentIndex);
//     currentIndex--;
//
//     // And swap it with the current element.
//     [array[currentIndex], array[randomIndex]] = [
//       array[randomIndex], array[currentIndex]];
//   }
//
//   return array;
// }

function drawLink() {

  // let rawLink = toRaw(link)
  // let targetRandom = prng_alea(String(easeOutCubic(rawLink.target.collectionIdx*10)));
  // let sourceRandom = prng_alea(String(easeOutCubic(rawLink.source.collectionIdx*10)));

  const geometry = new LineGeometry();
  geometry.setPositions([0, 0, 0, 1, 1, 1]);
  geometry.setColors([1.0, 1.0, 1.0, 1.0, 1.0, 1.0])

  const material = new LineMaterial({
    color: 0xffffff,
    linewidth: 1.5,
    vertexColors: true,
    dashed: true,
    dashScale: 1,
    dashSize: 1,
    gapSize: 2,
    transparent: true,
    opacity: 0.5,
    resolution: new THREE.Vector2(window.innerWidth, window.innerHeight),
    alphaToCoverage: false
  });

  const line = new Line2(geometry, material);
  line.computeLineDistances();
  line.boundingSphere = null;
  line.scale.set(1, 1, 1);
  return line;
}

function updateLinkPosition(line, start, end) {
  if (!(line instanceof Line2)) {
    return false;
  }

  const startR = 0;
  const endR = 0;
  const lineLen = Math.sqrt(
    ["x", "y", "z"]
      .map((dim) => Math.pow((end[dim] || 0) - (start[dim] || 0), 2))
      .reduce((acc, v) => acc + v, 0)
  );

  const test = [startR / lineLen, 1 - endR / lineLen]
    .map((t) =>
      ["x", "y", "z"].map((dim) => start[dim] + (end[dim] - start[dim]) * t)
    )
    .flat();

  line.geometry.setPositions(test);
  line.geometry.getAttribute("position").needsUpdate = true;
  line.computeLineDistances();
  return true;
}

export default {

  components: {
  },
  props: [
  ],
  data: function () {
    this.Graph = null;
    return {
      gui: null,
    }
  },
  mounted() {
  },
  created() {
  },
  computed: {
    graphData: function () {
      return this.$store.state.graphData;
    },
    selectedNode: function () {
      return this.$store.state.selectedNode;
    }

  },
  methods: {
    onResize: function () {
      // let el = document.getElementById('graph');
      // console.log("WIDTH: " + el.offsetWidth);
      // console.log("HEIGHT: " + el.offsetHeight);

      this.Graph.width(window.innerWidth)
      this.Graph.height(window.innerHeight)
    },

    initGraph: function () {
    const colors = [
      0x8c33f2,
      0x38cff2,
      0xf23652,
      0x430dba,
      0xff36ee,
      0x4583ff,
      0xff4527,
      0xff3b83,
      0x001fff,
      0xff0068,
      0x00b1ff,
      0x5d55ff
    ]

    // const hightlightColors = [
    //   0xc2fff6,
    //   0xfff18f,
    //   0x7676ff,
    //   0xffa38a,
    //   0xff83c1,
    //   0xffa76c,
    //   0xe36eff,
    //   0xe199ff,
    //   0xa576ff,
    //   0xff5794,
    //   0xffd69f,
    //   0xff7d7d
    // ]
    //
    // const secondaryHightlightColors = [
    //   0xffc7c7,
    //   0xffe9cf,
    //   0xffc7de,
    //   0xcfbbff,
    //   0xccffff,
    //   0xf1b6ff,
    //   0xffd3b6,
    //   0xffbde1,
    //   0xffc8bb,
    //   0xbbbbff,
    //   0xfff6c2,
    //   0xe1fffa
    // ]

      // var colorCombos = shuffle(cartesian(colors, colors, hightlightColors))
      // console.log(colorCombos)
      // var gui = new dat.GUI( { autoPlace: false } );
      // console.log(gui)
      // var customContainer = document.getElementById('datgui');
      // customContainer.appendChild(this.gui.domElement);
      // this.gui.add(controls, 'stariness', 2, 12, 2).setValue(controls.stariness).name('Stariness')
      // this.gui.add(controls, 'scale', 0, 4).name('Scale')
      // this.gui.add(controls, 'lineFactor', 0.0, 10.0).name('Line Thickness')
      // this.gui.add(controls, 'showImages').name('Display Images')
      // this.gui.add(controls, 'solidStars').setValue(controls.solidStars).name('Fill Stars')
      // this.gui.add(controls, 'thresholdStars').name('Threshold')

      totalNodes = this.graphData.nodes.length

      const elem = document.getElementById('graph');
      this.Graph = ForceGraph3D()
        (elem)
        // Graph data is stored in the Vue store (src/store/index.js)
        .graphData(this.graphData)
        .height(window.innerHeight)
        .width(window.innerWidth)
        .backgroundColor("rgba(0, 0, 0, 0)")
        .linkWidth(10)
        .nodeThreeObject((node) => {
          var collectionIdx
          var typeIdx
          node.collection.length > 0 ? collectionIdx = parseFloat(node.collectionIdx) + 2 : collectionIdx = 0
          node.type.length > 0 ? typeIdx = parseFloat(node.typeIdx) + 1 : typeIdx = 0
          // var lineFactor = node.date + 1.0
          var lineFactor = 2.0;
          // console.log(node.date)
          var nmd = parseFloat(node.numId)
          const textureImage = require('./textures/test_texture.png');
          const imgTexture = new THREE.TextureLoader().load(textureImage)
          let myrng = prng_alea(String(typeIdx * 100));
          var tuniforms = {
            time: { type: "f", value: 1.0 },
            stariness: { type: "f", value: controls.stariness },
            showImages: { type: "b", value: controls.showImages },
            solidStars: { type: "b", value: controls.solidStars },
            thresholdStars: { type: "b", value: controls.thresholdStars },
            tNodes: { type: "f", value: totalNodes },
            lineFactor: { type: "f", value: lineFactor },
            numId: { type: "f", value: nmd },
            m: { type: "f", value: collectionIdx },
            n1: { type: "f", value: collectionIdx },
            n2: { type: "f", value: nmd },
            typeId: { type: "f", value: typeIdx },
            image: { type: "t", value: imgTexture },
            opacity: { type: "f", value: 1.0 },
            typeRandom: {type: "f", value: myrng()},
            typeColor: {type: "vec3", value: new THREE.Color(colorCombos[typeIdx][0])},
            highlightColor: {type: "vec3", value: new THREE.Color(colorCombos[typeIdx][1])},
            secondaryHighlightColor: {type: "vec3", value: new THREE.Color(colorCombos[typeIdx][2])},
            collectionColor: {type: "vec3", value: new THREE.Color(colors[collectionIdx])}
          }
          var tdefines = {
            PI: 3.1415926,
          }
          var superShaderMaterial = new THREE.ShaderMaterial({
            uniforms: tuniforms,
            defines: tdefines,
            vertexShader: vShader,
            fragmentShader: fShader,
            side: THREE.DoubleSide,
            transparent: true,
          });
          var planeWidth = Math.floor(Math.random() * 20 + 15)
          var mesh = new THREE.Mesh(new THREE.PlaneGeometry(planeWidth, planeWidth), superShaderMaterial);
          mesh.name = node.id;
          mesh.renderOrder = 999;
          mesh.material.depthTest = false;
          mesh.material.depthWrite = false;
          mesh.onBeforeRender = function (renderer) { renderer.clearDepth(); };

          return mesh
        })
        .nodeLabel('')
        .linkPositionUpdate((line, { start, end }) =>
          updateLinkPosition(line, start, end)
        )
        .linkThreeObject((link) => drawLink(link))
        .onBackgroundClick(() => {
          this.onGraphBackgroundClick();

          this.Graph.scene().traverse(function (child) {
            if (child.type === 'Mesh' && child.material.uniforms) {
              child.material.uniforms.opacity.value = 1.0;
            }

            if (child.type === 'Line2') {
              // if (child.__data.target.numId === currentNode || child.__data.source.numId === currentNode) {
                child.material.opacity = 0.5
                child.material.dashed = true
                child.material.linewidth = 1.5
              // }
            }
          })
          const distance = 300;
          const distRatio = 1 + distance / Math.hypot(currentNodePos.x, currentNodePos.y, currentNodePos.z);

          const newPos = currentNodePos.x || currentNodePos.y || currentNodePos.z
            ? { x: currentNodePos.x * distRatio, y: currentNodePos.y * distRatio, z: currentNodePos.z * distRatio }
            : { x: 0, y: 0, z: distance }; // special case if node is in (0,0,0)
          this.Graph.cameraPosition(
            newPos,
            currentNodePos, // lookAt ({ x, y, z })
            3000
            // ms transition duration
          );

        })
        // .d3Force('link')
        // .distance(20)

        .onNodeHover(node => {

          if (!spin) {
            if (node) {
              this.onGraphNodeHovered(node.id)
            }
            else {
              this.onNoneHovered();
            }
          } else {
            this.onNoneHovered();
          }
        })

        // .dagMode("radialout")
        .onNodeClick(node => {

          // Notify parent Vue about click
          this.onGraphNodeClicked(node.id);
          currentNode = node.numId;
          this.Graph.scene().traverse(function (child) {
            if (child.type === 'Mesh' && child.name != node.id && child.material.uniforms) {
              child.material.uniforms.opacity.value = 0.2;
            }
            if (child.type === 'Mesh' && node.relatedEntries.includes(child.name) && child.material.uniforms) {
              child.material.uniforms.opacity.value = 0.7;
            }
            if (child.type === 'Mesh' && child.name === node.id && child.material.uniforms) {
              child.material.uniforms.opacity.value = 1.0;
            }
            if (child.type === 'Line2') {
              if (child.__data.target.numId !== currentNode && child.__data.source.numId != currentNode) {
                child.material.dashed = true
                child.material.linewidth = 1
                child.material.opacity = 0.25
              } else {
                child.material.dashed = false
                child.material.linewidth = 3.0
                child.material.opacity = 1.0
              }
            }
          })

          // Aim at node from outside it
          const distance = 140;
          const distRatio = 1 + distance / Math.hypot(node.x, node.y, node.z);

          const newPos = node.x || node.y || node.z
            ? { x: node.x * distRatio, y: node.y * distRatio, z: node.z * distRatio }
            : { x: 0, y: 0, z: distance }; // special case if node is in (0,0,0)

          currentNodePos = { x: node.x, y: node.y, z: node.z };

          // console.log(node)

          this.Graph.cameraPosition(
            newPos, // new position
            // node, // lookAt ({ x, y, z })
            currentNodePos,
            3000
            // ms transition duration
          );


        });
      this.Graph.d3VelocityDecay(0.7)

      var clock = new THREE.Clock();
      clock.start()
      this.clock = clock;
      this.scene = this.Graph.scene()

      var timeout;
      // var interval;
      var that = this
      document.onmousemove = function () {
        clearTimeout(timeout);
        spin = false
        timeout = setTimeout(function () {
          that.onGraphBackgroundClick()
          that.Graph.scene().traverse(function (child) {
            if (child.type === 'Mesh' && child.material.uniforms) {
              child.material.uniforms.opacity.value = 1.0;
            }
            if (child.type === 'Line2') {
              child.material.dashed = true
              child.material.linewidth = 1.5
            }
          })
          spin = true
        }, 120000);
      }

      // this.Graph.camera().setFocalLength(135);

    },
    updateGraph: function (scene, clock) {

      if(spin){
          this.Graph.cameraPosition({
            x: 500 * Math.sin(angle),
            z: 500 * Math.cos(angle)
          });
          angle += Math.PI / 1000;
      }
      var quat = this.Graph.camera().quaternion;

      var camera = this.Graph.camera();


      let allNodes = [];

      scene.traverse(function (child) {
        if (child.name) {
          var elapsed = clock.getElapsedTime();
          child.material.uniforms.time.value = elapsed
          child.quaternion.copy(quat)

          // Project to screen coords
          // https://github.com/mrdoob/three.js/issues/78#issuecomment-255811918
          let vector = child.position.clone();
          var windowWidth = window.innerWidth;

          var widthHalf = (windowWidth / 2);
          var heightHalf = (window.innerHeight / 2);

          vector.project(camera);

          vector.x = (vector.x * widthHalf) + widthHalf;
          vector.y = - (vector.y * heightHalf) + heightHalf;

          vector.z = THREE.MathUtils.mapLinear(child.position.z, camera.near, 100, 8, 2);
          // vector.z = 0;

          // if (zoomLevel > 150) vector.z = 0;

          allNodes.push({
            id: child.__data.id,
            name: child.__data.name,
            position: vector
          })
        }
      });

      this.$store.commit("set_all_nodes", allNodes);
    }
  },
  watch: {
    graphData: function (val) {
      this.Graph.graphData(val);
      this.Graph.d3ReheatSimulation();

      // this.Graph.onEngineStop(() => this.Graph.zoomToFit(1000));
    },
    selectedNode: function (val) {
      if (val == null) {
        this.Graph.scene().traverse(function (child) {
          if (child.type === 'Mesh' && child.material.uniforms) {
            child.material.uniforms.opacity.value = 1.0;
          }
        })
      }
    }
  }
}
