// import * as THREE from "three";
// import axios from "axios";
import axios from "axios";
import cachedDb from "../../data/local_db/cachedDb.json";


export default {
    components: {
    },
    props: [
    ],
    data: function () {
        return {
        }
    },
    mounted() {
    },
    created() {
    },
    computed: {
        filteredExhibitionData: function () {
            return this.$store.state.filteredExhibitionData;
        }
    },
    methods: {
        loadCachedData: function () {
            // Save graphData to the "store"
            console.log("[data] load cache");
            this.parseData(cachedDb.data, false);
        },
        loadNotionData: async function () {
            let notionTimeout = 30 * 1000;
            axios.defaults.timeout = notionTimeout;

            console.log("[notion] fetching");

            let url = (process.env.NODE_ENV === 'production')
                ? 'https://mit-queerspace-backend.herokuapp.com/data'
                : '/data'

            try {
                const response = await axios({
                    method: 'get',
                    url: url,
                    timeout: notionTimeout
                })
                // console.log(new Date().toUTCString());
                // console.log(response);

                // Parse data to get graph nodes
                this.parseData(response.data.data, false);
            }
            catch (err) {
                console.log(new Date().toUTCString());
                console.log(err);
            }

        },
        parseData: function (data, bFilteredData) {

            if (!data || data.length < 1) return;

            // if (JSON.stringify(data) === JSON.stringify(this.$store.state.exhibitionData)) return;

            if (!bFilteredData) {
                console.log("[data] new data");
                console.log(data);
                if (JSON.stringify(data) === JSON.stringify(this.$store.state.exhibitionData)) {
                    console.log("[data] exiting")
                    return;
                }
            }
            else {
                console.log("[data] filtering")
            }


            // Creating unique property sets
            let types = new Set(data.map(x => x.type).flat());
            if (!bFilteredData) this.$store.commit("set_types", types);

            let collections = new Set(data.map(x => x.collection).flat());
            if (!bFilteredData) this.$store.commit("set_collections", collections);

            let unsortedDates = new Set(data.map(x => x.content.date).flat());
            var sortedDates = Array.from(unsortedDates).sort()
            sortedDates.push(sortedDates.shift())

            let dates = new Set(sortedDates)
            if (!bFilteredData) this.$store.commit("set_dates", dates);

            let authors = new Set(data.map(x => x.author).flat());
            if (!bFilteredData) this.$store.commit("set_authors", authors);

            let locations = new Set(data.map(x => x.content.location).flat());
            if (!bFilteredData) this.$store.commit("set_locations", locations);



            let numIds = new Set(data.map(x => x.page_id));

            let rawLinks = data
                .map(x => (
                    x.related_entries.map(
                        entry => ({
                            source: x.page_id,
                            target: entry
                        })
                    )
                )).flat()

            // https://stackoverflow.com/questions/40166958/removing-duplicate-edges-from-an-array-for-a-d3-force-directed-graph/40167473#40167473
            rawLinks.forEach(function (d) {
                var sourceTemp = d.source, targetTemp = d.target;
                if (d.source > d.target) {
                    d.source = targetTemp;
                    d.target = sourceTemp;
                }
            });

            function removeDups(myArray) {
                myArray.sort();
                for (var i = 1; i < myArray.length;) {
                    if (myArray[i - 1].source === myArray[i].source
                        && myArray[i - 1].target === myArray[i].target) {
                        myArray.splice(i, 1);
                    } else {
                        i++;
                    }
                }
                return myArray;
            }

            rawLinks.filter((v, i, a) => a.findIndex(v2 => (v2.source === v.source)) === i)
            rawLinks = removeDups(rawLinks);

            const gData = {
                nodes: data.map(x => ({
                    id: x.page_id,
                    numId: [...numIds].indexOf(x.page_id, 0),
                    name: x.page_name,
                    type: x.type,
                    location: x.content.location,
                    author: x.author,
                    typeIdx: [...types].indexOf(x.type[0], 0),
                    collectionIdx: [...collections].indexOf(x.collection[0], 0),
                    collection: (x.collection) ? x.collection : "",
                    page_url: x.page_url,
                    numLinks: x.related_entries.length,
                    relatedEntries: x.related_entries,
                    date: [...dates].indexOf(x.content.date, 0)
                })),
                links: rawLinks
            };

            console.log(gData);

            if (bFilteredData) {
                console.log("[store] commit graph data & return");
                this.$store.commit("set_graph_data", gData);
                return;
            }

            console.log("[store] commit notion data");
            this.$store.commit("set_notion_graph_data", gData);
            // this.$store.commit("set_graph_data", gData);

            console.log("[store] commit exhibition data");
            this.$store.commit("set_exhibition_data", data);

            if (Object.keys(this.$store.state.cachedGraphData).length === 0) {
                console.log("[store] commit cached graph data");
                this.$store.commit("set_graph_data", gData);
                this.$store.commit("set_cached_graph_data", gData);
            }
            else {
                console.log("[store] commit graph data");
                this.$store.commit("set_graph_data", gData);
            }
        },
    },
    watch: {
        filteredExhibitionData: function () {
            this.parseData(this.filteredExhibitionData, true);
        }
    }
}
