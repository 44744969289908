<template>
  <div>
    <Navbar />
    <BackgroundGraphicsSimplex />
    <GraphGraphics />
    <NodeCard />
    <FiltersDashboard />
    <NodeTitle />
    <Footer />
    <InfoCard />
    <AboutCard />

  </div>
</template>

<script>
// @ is an alias to /src
import GraphGraphics from "@/components/GraphGraphics.vue";
import BackgroundGraphicsSimplex from "@/components/BackgroundGraphicsSimplex.vue";
import NodeCard from "@/components/NodeCard.vue";
import Navbar from "@/components/Navbar.vue";
import Footer from "@/components/Footer.vue";
import FiltersDashboard from "@/components/FiltersDashboard.vue";
import NodeTitle from "@/components/NodeTitle.vue";
import InfoCard from "@/components/InfoCard.vue";
import AboutCard from "@/components/AboutCard.vue";

export default {
  name: "InterfaceView",
  components: {
    GraphGraphics,
    BackgroundGraphicsSimplex,
    NodeCard,
    Navbar,
    FiltersDashboard,
    NodeTitle,
    Footer,
    InfoCard,
    AboutCard
  },
  data: function () {
    return {};
  },
  mounted: function () {
  },
  computed: {
    cssProps: function () {
      return {};
    },
    width: function () {
      return window.innerWidth;
    },
  },
};
</script>

<style lang="scss">
</style>
