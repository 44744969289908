<template>
  <div id="bg-graphics"></div>
</template>

<script>
import simplexFragShader from "../mixins/graphics/shaders/simplexBgShader";
import vertexShader from "../mixins/graphics/shaders/vertexShader.js";
import * as THREE from "three";



export default {
  name: "BackgroundGraphicsSimplex",
  props: [],
  mixins: [],
  data: function () {
    this.bgCamera = null;
    this.bgScene = null;
    this.bgRenderer = null;
    this.backgroundPlane = null;
    return {
      width: window.innerWidth,
      height: window.innerHeight,
      texture: require("@/assets/backgrounds/background_2.png")
    };
  },
  methods: {
    initSketch: function () {
      let container = document.getElementById("bg-graphics");

      this.bgCamera = new THREE.OrthographicCamera(
        -1, // left
        1, // right
        1, // top
        -1, // bottom
        -1, // near,
        1 // far
      );

      this.bgScene = new THREE.Scene();

      this.bgRenderer = new THREE.WebGLRenderer();
      // this.renderer.setSize(this.width, this.height);

      this.bgRenderer.setSize(this.width, this.height);

      this.bgScene.background = new THREE.Color(0xff0000);

      if (container) container.appendChild(this.bgRenderer.domElement);
    },
    animate: function () {
      requestAnimationFrame(this.animate);

      this.backgroundPlane.material.uniforms.u_time.value += 0.005;

      this.bgRenderer.render(this.bgScene, this.bgCamera);
    },
    initBackgroundTexture: function () {

      let shaderMaterial = new THREE.ShaderMaterial({
        uniforms: {
          u_time: { value: 1.0 },
          u_resolution: { value: new THREE.Vector2(this.width, this.height) },
          tex: { type: "t", value: new THREE.TextureLoader().load( this.texture ) }
        },
        fragmentShader: simplexFragShader,
        vertexShader: vertexShader,
      });

      let geo = new THREE.PlaneGeometry(2, 2, 8, 8);

      this.backgroundPlane = new THREE.Mesh(geo, shaderMaterial);
      this.backgroundPlane.name = "background_preview_sprite";

      this.backgroundPlane.position.x = 0;
      this.bgScene.add(this.backgroundPlane);
    },
    onResize() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;

      this.bgRenderer.setSize(this.width, this.height);
    }
  },
  mounted: function () {
    this.initSketch();
    this.initBackgroundTexture();

    this.animate();
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  unmounted() {
    window.removeEventListener("resize", this.onResize);
  },
  computed: {
    cssProps: function () {
      return {
        "--width": window.innerWidth,
        "--height": window.innerHeight,
      };
    },
  },
  watch: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.bg-graphics {
  position: absolute;
  top: 0px;
  left: 0px;

  z-index: -1000;

  width: 100vw;
  height: 100vh;
}
</style>