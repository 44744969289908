<template>
  <nav
    class="navbar fixed-top p-4" ref="qsNav"
    :style="{
      width: '100%',
    }"
  >
    <div class="row" style="width: 100vw" :style="cssProps">
      <!-- <div class="d-flex col-md-4 nav-item justify-content-start">
        <h1></h1>
      </div> -->
      <div
        class="
          d-flex
          col-md-4 col-sm-1 col-1
          nav-item
          justify-content-start
          align-items-start
        "
      >
        <h3>
          <router-link to="/"><img style="max-height: 30px;" :src="mitLogo" /></router-link>
        </h3>
      </div>

    </div>
  </nav>
</template>

<script>
import Resize from "@/mixins/resize.js";

export default {
  name: "Navbar",
  props: ["name"],
  mixins: [Resize],
  data: function () {
    return {
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      bNarrowScreen: false,
      mitLogo: require("@/assets/icons/MIT_logo_grey.svg"),
      navHeight: 0,
    };
  },
  methods: {
    onToggleFilters() {
      this.$store.commit("toggle_filters");
    },
    onResizeNav() {
      this.navHeight = this.$refs.qsNav.clientHeight;
    }
  },
  watch: {
    navHeight: function(val) {
      this.$store.commit("set_navbar_height", val);
    }
  },
  computed: {
    cssProps: function () {
      return {
        "--width": "50px",
        "--pointerCursor": "url(" + window.pointerCursor + ") 6 6, pointer",
      };
    },
    showFilters: function () {
      return this.$store.state.showFilters;
    },
  },
  created() {
    window.addEventListener("resize", this.onResize);
    window.addEventListener("resize", this.onResizeNav);
  },
  mounted() {
    this.navHeight = this.$refs.qsNav.clientHeight;
  },
  unmounted() {
    window.removeEventListener("resize", this.onResize);
    window.removeEventListener("resize", this.onResizeNav);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
// @import "./../assets/scss/_variables.scss";

a {
  color: rgb(255, 255, 255) !important;
  text-decoration: none !important;
}
.navbar {
  background-color: transparent;

  z-index: 60000 !important;
}
.nav-item {
  font-family: "Hatton";
  text-transform: uppercase;
}
.nav-item a:hover {
  /* text-decoration: underline; */
  /* border-bottom: 1px solid white;
    display: inline-block;
    line-height: 0.85; */
  color: rgb(255, 255, 255);
  cursor: var(--pointerCursor);
}
a router-link-active {
  text-decoration: none !important;
  color: rgb(255, 255, 255);
}

// .btn-white {
//   background-color: white !important;
//   color: black !important;

//   box-shadow: none !important;
// }

// .btn-white:hover {
//   box-shadow: none !important;

//   color: black !important;
// }

// .btn-white h3 {
//   color: black !important;
// }
</style>
