<template>
  <nav
    class="navbar fixed-bottom p-4 m-2 mb-3"
    :style="{
      height: navbarHeight,
      width: '100%',
    }"
  >
    <div class="row" style="width: 100vw" :style="cssProps">
      <div class="d-flex col-md-4 nav-item justify-content-start">
        <h1></h1>
      </div>
      <div
        class="
          d-flex
          col-md-4 col-sm-12
          nav-item
          justify-content-center
          align-items-start
        "
      ></div>
      <div
        class="
          d-flex
          col-md-12 col-lg-12
          p-4
          pt-0
          nav-item
          justify-content-end
          align-items-start
        "
      >
        <div
          @click="onToggleAbout()"
          class="btn m-0 me-3"
          :class="{ 'btn-white': showAbout }"
        >
          <h3 class="m-0">About</h3>
        </div>
        <div class="btn m-0 me-3">
          <a :href="formUrl" target="_blank">
            <h3 class="m-0">Contribute &#x2197;</h3>
          </a>
        </div>
        <div
          @click="onToggleLabels()"
          class="btn m-0 me-3"
          :class="{ 'btn-white': showLabels }"
        >
          <h3 class="m-0">Labels &#x2196;</h3>
        </div>
        <div
          @click="onToggleHelp()"
          class="btn m-0"
          :class="{ 'btn-white': showHelp }"
        >
          <h3 class="m-0">
            Help <span v-if="showHelp">&#x2193;</span
            ><span v-else>&#x2191;</span>
          </h3>
        </div>
        <!-- <div @click="onToggleHelp()" class="btn  m-0" v-if="!showHelp">
            <h3 class="m-0">Help &#x2191;</h3>
          </div> -->
      </div>
    </div>
  </nav>
</template>

<script>
import Resize from "@/mixins/resize.js";

export default {
  name: "Navbar",
  props: ["name"],
  mixins: [Resize],
  data: function () {
    return {
      windowHeight: window.innerHeight,
      windowWidth: window.innerWidth,
      bNarrowScreen: false,
      formUrl: "https://forms.gle/1XxBp5Fnkp1VWbW18",
    };
  },
  methods: {
    onToggleHelp() {
      this.$store.commit("toggle_help");
    },
    onToggleLabels() {
      this.$store.commit("toggle_labels");
    },
    onToggleAbout() {
      this.$store.commit("toggle_about");
    },
  },
  computed: {
    cssProps: function () {
      return {
        "--width": "50px",
        "--pointerCursor": "url(" + window.pointerCursor + ") 6 6, pointer",
      };
    },
    showHelp: function () {
      return this.$store.state.showHelp;
    },
    showLabels: function () {
      return this.$store.state.showLabels;
    },
    showAbout: function () {
      return this.$store.state.showAbout;
    },

    navbarHeight: function () {
      return window.innerWidth > 768
        ? this.$store.state.navbarDims[1] + "px"
        : this.$store.state.navbarDims.navbarDims[0] + "px";
    },
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  unmounted() {
    window.removeEventListener("resize", this.onResize);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
// @import "./../assets/scss/_variables.scss";

a {
  color: rgb(255, 255, 255) !important;
  text-decoration: none !important;
}
.navbar {
  background-color: transparent;

  z-index: 60000 !important;
}
.nav-item {
  font-family: "Hatton";
  text-transform: uppercase;
}
.nav-item a:hover {
  /* text-decoration: underline; */
  /* border-bottom: 1px solid white;
    display: inline-block;
    line-height: 0.85; */
  color: rgb(255, 255, 255);
  cursor: var(--pointerCursor);
}
a router-link-active {
  text-decoration: none !important;
  color: rgb(255, 255, 255);
}
</style>
