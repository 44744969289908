<template>
  
    <div class="row justify-content-end">
      <div class="col-lg-3 col-md-3 collections-card m-2 p-2 align-self-end">
        <div class="node-card-inner mt-2 mb-1">
          <div class="row">
            <div class="col-12">
              <a @click="onToggle()"
                ><h3 class="hover-underline-animation"> Filter by {{ filterProperty }} <span v-if="isVisible">-</span><span v-else>+</span></h3></a
              >
            </div>
          </div>
          <div v-if="isVisible" class="mt-4">
            <div v-for="item in items" :key="item">
              <div class="row m-2 mt-1 mb-3 justify-content-start">
                <button
                  type="button"
                  @click="onFilter(item)"
                  class="btn btn-fullw me-3"
                  :class="{ 'btn-white': (item == filterItem) }"
                >
                  <p>{{ item }} &#x2192;</p>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

</template>

<script>

export default {
  name: "FiltersDashboard",
  props: {
    filterProperty: String,
  },
  mixins: [],
  components: {

  },
  data: function () {
    return {
      isVisible: false,
    };
  },
  methods: {
    onFilter(clickedItem) {
      let filterData = {
        clickedItem: clickedItem,
        filterProp: this.filterProperty
      }
      this.$store.commit("set_selected_item", filterData);
    },
    onToggle() {
      this.isVisible = !this.isVisible;
      this.$emit('onToggleFilter', this.filterProperty);
    },
    hide() {
      this.isVisible = false;
    }
  },
  mounted: function () {},
  created() {
    window.addEventListener("resize", this.onResize);
  },
  unmounted() {
    window.removeEventListener("resize", this.onResize);
  },
  computed: {
    showFilters() {
      return this.$store.state.showFilters;
    },
    items: function () {
      return this.$store.state[this.filterProperty + "s"];
    },
    filterItem: function () {
      return this.$store.state.filterItem;
    }
  },
  watch: {
    showFilters: function (val) {
      if (!val) {
        this.isVisible = false;
      }
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.container-fluid {
  position: absolute;
  top: 80px;
  left: 0px;

  color: white;

  z-index: 20000;
  pointer-events: none;
}

.collections-card {
  pointer-events: all !important;
  backdrop-filter: blur(20px);

  // border: 1px white solid;
  box-shadow: 0 0 0px 1px white;

  margin: 10px;

  border-radius: 20px;

  padding-bottom: 0px;

  max-height: 30vh !important;
  overflow: hidden;

  display: flex;
  flex-direction: column;
}

.node-card-inner {
  overflow-y: auto;
}

.btn-fullw {
  width: 100% !important;
}

/* width */
::-webkit-scrollbar {
  width: 1px;
  height: 90% !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  height: 90% !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(255, 255, 255);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(255, 255, 255);
}

.hover-underline-animation {
  display: inline-block;
  position: relative;
  text-align: left;
}

.hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1.5px;
  bottom: 0;
  left: 0;
  background-color: white;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.selected {
  background-color: white !important;
  color: black !important;

  border: none !important;
}
</style>