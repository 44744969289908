<template>
  <div v-if="showFilters" class="container-fluid w-100 p-3 ps-3 pb-0 pt-2 mt-1">
    <div class="filter-container p-2">
      <div v-for="filter in filters" :key="filter">
        <FilterCard
          :filterProperty="filter"
          ref="filterComponent"
          @onToggleFilter="onToggleFilter"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FilterCard from "@/components/FilterCard.vue";

export default {
  name: "FiltersDashboard",
  props: [],
  mixins: [],
  components: {
    // Navbar,
    FilterCard,
  },
  data: function () {
    return {
      name: "test",
      width: window.innerWidth,
      height: window.innerHeight,
      filters: ["type", "collection", "author", "location", "date"],
    };
  },
  methods: {
    onToggleFilter(filterType) {
      for (let index in this.filters) {
        if (this.filters[index] != filterType) {
          this.$refs.filterComponent[index].hide();
        }
      }
    },
  },
  mounted: function () {},
  created() {
    window.addEventListener("resize", this.onResize);
  },
  unmounted() {
    window.removeEventListener("resize", this.onResize);
  },
  computed: {
    selectedCollection() {
      return this.$store.state.selectedCollection;
    },
    selectedType() {
      return this.$store.state.selectedType;
    },
    showFilters() {
      return this.$store.state.showFilters;
    },

    collections: function () {
      return this.$store.state.collections;
    },
    types: function () {
      return this.$store.state.types;
    },
    cssProps: function () {
      return {
        "--width": window.innerWidth,
        "--height": window.innerHeight,
      };
    },
  },
  watch: {
    showFilters: function (val) {
      if (!val) {
        this.showCollections = false;
        this.showTypes = false;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.container-fluid {
  position: absolute;
  top: 80px;
  left: 0px;

  color: white;

  z-index: 20000;
  pointer-events: none;
}

// .filter-container {
//   max-height: 60vh !important;

//   overflow: scroll !important;


//   // pointer-events: all;
// }

.collections-card {
  pointer-events: all !important;
  backdrop-filter: blur(20px);

  // border: 1px white solid;
  box-shadow: 0 0 0px 1px white;

  margin: 10px;

  border-radius: 20px;

  padding-bottom: 0px;

  max-height: 50vh !important;
  overflow: hidden;

  display: flex;
  flex-direction: column;
}

.node-card-inner {
  overflow-y: auto;
}

/* width */
::-webkit-scrollbar {
  width: 1px;
  height: 90% !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  height: 90% !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(255, 255, 255);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(255, 255, 255);
}

.hover-underline-animation {
  display: inline-block;
  position: relative;
  text-align: left;
}

.hover-underline-animation:after {
  content: "";
  position: absolute;
  width: 100%;
  transform: scaleX(0);
  height: 1.5px;
  bottom: 0;
  left: 0;
  background-color: white;
  transform-origin: bottom right;
  transition: transform 0.25s ease-out;
}

.hover-underline-animation:hover:after {
  transform: scaleX(1);
  transform-origin: bottom left;
}

.selected {
  background-color: white !important;
  color: black !important;

  border: none !important;
}
</style>