const fragmentShader = `
uniform float time;
uniform float numId;
uniform float lineFactor;
uniform float m;
uniform float n1;
uniform float n2;
uniform float stariness;
uniform float tNodes;
uniform float typeRandom;
uniform vec3 typeColor;
uniform vec3 highlightColor;
uniform vec3 secondaryHighlightColor;
uniform vec3 collectionColor;
uniform float typeId;
uniform bool showImages;
uniform bool solidStars;
uniform bool thresholdStars;
uniform float opacity;
varying vec2 vUv;
uniform sampler2D image;

/*
  Calculates how close the given point is to the
  superformula curve specified by the given parameters.
  Note that just m is used instead of m1 and m2, since
  for a lot of interesting curves m1 and m2 are the same anyway.
*/

float circle(in vec2 _st, in float _radius){
    vec2 dist = _st-vec2(0.5);
	return 1.-smoothstep(_radius-(_radius*0.01),
                         _radius+(_radius*0.01),
                         dot(dist,dist)*4.0);
}

float shape(vec2 xy, float m, float n1, float n2, float n3, vec2 offset, float rot, float scale) {
    xy += offset;

    /* Calculate 2d polar coordinates of the point. Note that phi needs to be
       in the 0..2pi range, so we shift the result of atan by pi for points
       with x < 0

     */
    float r   = length(xy) * (1.0/scale);
    float phi = atan(xy.y / xy.x) + (xy.x < .0 ? PI : 0.0) + rot;

    float f = 0.0;

    if(m > 0.0){
      f   = pow(pow(abs(cos(stariness * m * phi / 4.0)), n2) + pow(abs(sin(stariness*m * phi / 4.0)), sin(time+m) * n3), -1.0/m);
    }else{
      f = distance(vUv,vec2(0.7));
    }

    // THRESHOLDED
	// return max(0.0, round(1.0 - lineFactor * abs(r-f)));

  // SMOOTH
  // return max(0.0, 1.0 - lineFactor * abs(r-f));
  // return max(0.0, 1.0 - lineFactor * abs(1.0+ r-f));

  if(solidStars && !thresholdStars){
    return max(0.0, 1.0 - lineFactor * (r-f));
  }else if(solidStars && thresholdStars){
    return max(0.0, round(1.0 - lineFactor * abs(1.0+0.25*r-f)));
  }
  else if(thresholdStars){
    // return max(0.0, round(1.0 - lineFactor * abs(r-f)));
    return max(0.0, smoothstep(0.2, 0.8, 1.0-lineFactor * abs(r-f)));
  }
  else{
    return max(0.0, 1.0 - lineFactor * abs(r-f));
  }
}

void main()
{
    /* Calculate coordinates for this pixel and apply aspect ratio correction*/
    vec2 xy = -1.0 + 2.0 * vUv;
    xy *= 8.0;

    float rot = float(int(time * 5.0 * m) % 1440)*PI / 360.0;
    float s1 =
        shape(xy, m, 2.0, 0.5, 9.0,
              vec2(0.0), rot, 4.0);

    float c1 = circle(vUv, 0.001);

    // Calculate noise and sample texture
    float mdf = 0.1; // increase for noise amount
    float noise = (fract(sin(dot(xy, vec2(12.9898,78.233)*2.0)) * 43758.5453));

    float pct = 0.0;
    float pct1 = 0.0;
    pct = 2.*distance(vUv,vec2(0.6))*distance(vUv,vec2(0.6));
    pct1 =2.* distance(vUv,vec2(0.2,0.5))*distance(vUv,vec2(0.2,0.5));


    if(showImages)
    {
      gl_FragColor = texture2D(image, vUv);
      vec3 mixColor = vec3(m/10.0 + noise*0.25, numId/(m+tNodes)+ noise*0.25, s1+ noise*0.25);
      gl_FragColor.rgb = mix( gl_FragColor.rgb, mixColor, 0.25 );
    }
    else {
      vec3 baseColor = vec3(typeColor.r + noise*0.15, typeColor.g + noise*0.15, typeColor.b+ noise*0.15);
      vec3 secondaryColor = vec3(highlightColor.r + noise*0.15, highlightColor.g + noise*0.15, highlightColor.b + noise*0.15);
      vec3 tertiaryColor = vec3(secondaryHighlightColor.r + noise*0.15,secondaryHighlightColor.g + noise*0.15, secondaryHighlightColor.b + noise*0.15);
      vec4 circle = vec4(0.,0.,0.,c1);
      vec4 thisColor = vec4(mix(mix(baseColor, secondaryColor,pct), tertiaryColor, pct1), min(s1, opacity));
      gl_FragColor = thisColor + circle;
    }
}
`
export default fragmentShader
