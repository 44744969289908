
export default {
    components: {
    },
    props: [
    ],
    data: function () {
        return {
        }
    },
    mounted() {
    },
    created() {
    },
    computed: {
    },
    methods: {
        onResize() {
            this.windowHeight = window.innerHeight;
            this.windowWidth = window.innerWidth;
            if (this.windowWidth < 768) {
                this.bNarrowScreen = true;
            } else {
                this.bNarrowScreen = false;
            }
        },

    },
    watch: {
    }
}
