const colorCombos =
[
    [
        45567,
        45567,
        10843903
    ],
    [
        16729383,
        4555775,
        16753546
    ],
    [
        8191,
        45567,
        16754540
    ],

    [
        6116863,
        3723250,
        12779510
    ],
    [
        16725742,
        8191,
        16766623
    ],

    [
        16711784,
        8191,
        7763711
    ],

    [
        4394426,
        45567,
        16753546
    ],

    [
        4555775,
        16729383,
        16754540
    ],
    [
        3723250,
        6116863,
        10843903
    ],

    [
        8191,
        6116863,
        16766623
    ],

    [
        15873618,
        15873618,
        16743805
    ],
    [
        45567,
        16725742,
        16773519
    ],
    [
        4394426,
        3723250,
        12779510
    ],

    [
        16725742,
        16725742,
        7763711
    ],
    [
        16729383,
        8191,
        16743805
    ],
    [
        9188338,
        15873618,
        16773519
    ],
    [
        6116863,
        16729383,
        16734100
    ],
    [
        9188338,
        8191,
        16753546
    ],
    [
        4394426,
        45567,
        7763711
    ],
    [
        16725742,
        9188338,
        16745409
    ],
    [
        8191,
        3723250,
        14785023
    ],
    [
        45567,
        4555775,
        16734100
    ],
    [
        16725742,
        9188338,
        16753546
    ],
    [
        4555775,
        16729383,
        16766623
    ],
    [
        16729383,
        16729383,
        7763711
    ],
    [
        6116863,
        8191,
        16753546
    ],
    [
        6116863,
        8191,
        16754540
    ],
    [
        6116863,
        16725742,
        16753546
    ],
    [
        4394426,
        6116863,
        7763711
    ],
    [
        15873618,
        16729383,
        16745409
    ],
    [
        16726915,
        4555775,
        14905087
    ],
    [
        16711784,
        6116863,
        16734100
    ],
    [
        16729383,
        6116863,
        14905087
    ],
    [
        6116863,
        15873618,
        16754540
    ],
    [
        16725742,
        16711784,
        16754540
    ],
    [
        16726915,
        4394426,
        7763711
    ],
    [
        4555775,
        8191,
        10843903
    ],
    [
        16729383,
        45567,
        16773519
    ],
    [
        16729383,
        45567,
        7763711
    ],
    [
        16725742,
        16726915,
        14905087
    ],
    [
        4394426,
        4555775,
        16773519
    ],
    [
        16711784,
        6116863,
        16754540
    ],
    [
        3723250,
        16711784,
        12779510
    ],
    [
        4394426,
        6116863,
        16773519
    ],
    [
        16725742,
        45567,
        16753546
    ],
    [
        16726915,
        16725742,
        16753546
    ],
    [
        16729383,
        4555775,
        16766623
    ],
    [
        16729383,
        16729383,
        14905087
    ],
    [
        16729383,
        3723250,
        16766623
    ],
    [
        8191,
        8191,
        16743805
    ],
    [
        6116863,
        6116863,
        16766623
    ],
    [
        4394426,
        15873618,
        16754540
    ],
    [
        6116863,
        16726915,
        7763711
    ],
    [
        45567,
        4555775,
        14905087
    ],
    [
        16725742,
        4394426,
        16766623
    ],
    [
        15873618,
        3723250,
        10843903
    ],
    [
        16725742,
        6116863,
        16734100
    ],
    [
        45567,
        15873618,
        16766623
    ],
    [
        16725742,
        4394426,
        14785023
    ],
    [
        15873618,
        8191,
        14905087
    ],
    [
        9188338,
        15873618,
        14905087
    ],
    [
        8191,
        4394426,
        7763711
    ],
    [
        16711784,
        9188338,
        14785023
    ],
    [
        8191,
        15873618,
        10843903
    ],
    [
        16725742,
        16725742,
        14785023
    ],
    [
        4394426,
        16711784,
        16754540
    ],
    [
        16711784,
        16726915,
        16773519
    ],
    [
        15873618,
        3723250,
        16745409
    ],
    [
        16726915,
        4555775,
        12779510
    ],
    [
        16729383,
        16725742,
        16766623
    ],
    [
        9188338,
        4555775,
        16745409
    ],
    [
        9188338,
        45567,
        16745409
    ],
    [
        9188338,
        4394426,
        16754540
    ],
    [
        9188338,
        16711784,
        10843903
    ],
    [
        4394426,
        16729383,
        12779510
    ],
    [
        16729383,
        16725742,
        16743805
    ],
    [
        16711784,
        16725742,
        16734100
    ],
    [
        3723250,
        15873618,
        16754540
    ],
    [
        4394426,
        45567,
        16745409
    ],
    [
        4555775,
        6116863,
        10843903
    ],
    [
        4555775,
        16711784,
        12779510
    ],
    [
        6116863,
        3723250,
        14905087
    ],
    [
        8191,
        16725742,
        16754540
    ],
    [
        4394426,
        6116863,
        12779510
    ],
    [
        4394426,
        4394426,
        16754540
    ],
    [
        45567,
        6116863,
        12779510
    ],
    [
        9188338,
        16725742,
        12779510
    ],
    [
        16711784,
        3723250,
        16753546
    ],
    [
        4394426,
        3723250,
        16773519
    ],
    [
        3723250,
        4394426,
        16745409
    ],
    [
        6116863,
        16711784,
        16734100
    ],
    [
        16729383,
        9188338,
        7763711
    ],
    [
        3723250,
        45567,
        16753546
    ],
    [
        8191,
        8191,
        16766623
    ],
    [
        6116863,
        4555775,
        16753546
    ],
    [
        16726915,
        45567,
        16766623
    ],
    [
        16726915,
        8191,
        14785023
    ],
    [
        4394426,
        16711784,
        16734100
    ],
    [
        9188338,
        4555775,
        16766623
    ],
    [
        6116863,
        4394426,
        14905087
    ],
    [
        6116863,
        45567,
        16754540
    ],
    [
        16711784,
        6116863,
        16773519
    ],
    [
        3723250,
        8191,
        16745409
    ],
    [
        16726915,
        6116863,
        16753546
    ],
    [
        16726915,
        4555775,
        14785023
    ],
    [
        15873618,
        4555775,
        16766623
    ],
    [
        6116863,
        6116863,
        10843903
    ],
    [
        15873618,
        4394426,
        16745409
    ],
    [
        3723250,
        16711784,
        16745409
    ],
    [
        16725742,
        16725742,
        10843903
    ],
    [
        15873618,
        16729383,
        16743805
    ],
    [
        15873618,
        15873618,
        12779510
    ],
    [
        16729383,
        9188338,
        10843903
    ],
    [
        16711784,
        3723250,
        12779510
    ],
    [
        4555775,
        16726915,
        16753546
    ],
    [
        16726915,
        45567,
        16773519
    ],
    [
        6116863,
        6116863,
        12779510
    ],
    [
        16725742,
        16725742,
        16734100
    ],
    [
        45567,
        3723250,
        7763711
    ],
    [
        15873618,
        15873618,
        16753546
    ],
    [
        15873618,
        45567,
        16745409
    ],
    [
        9188338,
        16725742,
        10843903
    ],
    [
        3723250,
        3723250,
        16734100
    ],
    [
        3723250,
        4394426,
        16766623
    ],
    [
        16726915,
        6116863,
        16734100
    ],
    [
        45567,
        3723250,
        16766623
    ],
    [
        45567,
        9188338,
        14905087
    ],
    [
        16726915,
        4394426,
        16745409
    ],
    [
        8191,
        3723250,
        10843903
    ],
    [
        16729383,
        3723250,
        16745409
    ],
    [
        4394426,
        16729383,
        16754540
    ],
    [
        6116863,
        16725742,
        16754540
    ],
    [
        16729383,
        16711784,
        16734100
    ],
    [
        16711784,
        4555775,
        16745409
    ],
    [
        9188338,
        16729383,
        16766623
    ],
    [
        4555775,
        45567,
        16745409
    ],
    [
        6116863,
        16726915,
        16753546
    ],
    [
        4394426,
        3723250,
        16753546
    ],
    [
        9188338,
        8191,
        16754540
    ],
    [
        16725742,
        8191,
        14905087
    ],
    [
        15873618,
        6116863,
        14905087
    ],
    [
        15873618,
        16711784,
        12779510
    ],
    [
        3723250,
        16725742,
        10843903
    ],
    [
        45567,
        16729383,
        10843903
    ],
    [
        4394426,
        16711784,
        16753546
    ],
    [
        16711784,
        16725742,
        16745409
    ],
    [
        16725742,
        4555775,
        16773519
    ],
    [
        4394426,
        8191,
        14905087
    ],
    [
        16725742,
        3723250,
        16753546
    ],
    [
        3723250,
        4555775,
        16734100
    ],
    [
        4555775,
        15873618,
        16745409
    ],
    [
        6116863,
        4394426,
        16766623
    ],
    [
        45567,
        6116863,
        16754540
    ],
    [
        9188338,
        16729383,
        10843903
    ],
    [
        16726915,
        8191,
        16745409
    ],
    [
        6116863,
        4394426,
        7763711
    ],
    [
        3723250,
        15873618,
        16743805
    ],
    [
        16711784,
        15873618,
        16766623
    ],
    [
        3723250,
        9188338,
        16753546
    ],
    [
        4555775,
        8191,
        14905087
    ],
    [
        16729383,
        16726915,
        16754540
    ],
    [
        4394426,
        8191,
        16766623
    ],
    [
        15873618,
        15873618,
        7763711
    ],
    [
        16729383,
        16711784,
        16743805
    ],
    [
        4555775,
        9188338,
        16734100
    ],
    [
        16711784,
        16711784,
        16734100
    ],
    [
        4555775,
        6116863,
        16734100
    ],
    [
        6116863,
        16725742,
        16766623
    ],
    [
        4394426,
        8191,
        16773519
    ],
    [
        15873618,
        16725742,
        7763711
    ],
    [
        6116863,
        16726915,
        16754540
    ],
    [
        15873618,
        4555775,
        16745409
    ],
    [
        16711784,
        15873618,
        16745409
    ],
    [
        16726915,
        16729383,
        14785023
    ],
    [
        9188338,
        16711784,
        14785023
    ],
    [
        16711784,
        8191,
        14785023
    ],
    [
        3723250,
        6116863,
        16754540
    ],
    [
        3723250,
        45567,
        14905087
    ],
    [
        16711784,
        4555775,
        14905087
    ],
    [
        4555775,
        16725742,
        16766623
    ],
    [
        6116863,
        8191,
        16745409
    ],
    [
        16729383,
        15873618,
        16754540
    ],
    [
        16725742,
        16729383,
        16743805
    ],
    [
        4394426,
        3723250,
        16745409
    ],
    [
        16725742,
        4555775,
        12779510
    ],
    [
        16726915,
        3723250,
        14785023
    ],
    [
        16711784,
        45567,
        16753546
    ],
    [
        16729383,
        3723250,
        14785023
    ],
    [
        45567,
        16729383,
        14905087
    ],
    [
        6116863,
        15873618,
        7763711
    ],
    [
        16711784,
        6116863,
        10843903
    ],
    [
        8191,
        6116863,
        16773519
    ],
    [
        15873618,
        4394426,
        14785023
    ],
    [
        16725742,
        16711784,
        16773519
    ],
    [
        16711784,
        8191,
        16745409
    ],
    [
        45567,
        4394426,
        16745409
    ],
    [
        4394426,
        8191,
        7763711
    ],
    [
        3723250,
        16725742,
        16743805
    ],
    [
        16711784,
        4394426,
        16734100
    ],
    [
        9188338,
        4394426,
        16743805
    ],
    [
        16726915,
        9188338,
        16734100
    ],
    [
        16729383,
        16711784,
        16745409
    ],
    [
        4555775,
        4555775,
        16753546
    ],
    [
        3723250,
        3723250,
        7763711
    ],
    [
        16726915,
        16711784,
        16734100
    ],
    [
        4555775,
        15873618,
        10843903
    ],
    [
        6116863,
        16725742,
        16743805
    ],
    [
        16711784,
        4394426,
        14905087
    ],
    [
        16725742,
        15873618,
        12779510
    ],
    [
        16726915,
        4555775,
        16766623
    ],
    [
        9188338,
        3723250,
        16743805
    ],
    [
        16725742,
        16725742,
        12779510
    ],
    [
        16726915,
        15873618,
        14785023
    ],
    [
        4555775,
        8191,
        16754540
    ],
    [
        9188338,
        9188338,
        16753546
    ],
    [
        8191,
        16726915,
        7763711
    ],
    [
        9188338,
        4555775,
        16743805
    ],
    [
        8191,
        15873618,
        14785023
    ],
    [
        3723250,
        8191,
        16773519
    ],
    [
        3723250,
        16729383,
        16734100
    ],
    [
        3723250,
        15873618,
        7763711
    ],
    [
        15873618,
        15873618,
        10843903
    ],
    [
        4555775,
        9188338,
        16766623
    ],
    [
        16711784,
        16729383,
        16745409
    ],
    [
        16725742,
        45567,
        16745409
    ],
    [
        6116863,
        15873618,
        16734100
    ],
    [
        16711784,
        3723250,
        16743805
    ],
    [
        16711784,
        3723250,
        10843903
    ],
    [
        3723250,
        8191,
        16766623
    ],
    [
        16711784,
        16729383,
        16734100
    ],
    [
        3723250,
        8191,
        14905087
    ],
    [
        3723250,
        16711784,
        16773519
    ],
    [
        45567,
        16725742,
        14785023
    ],
    [
        16711784,
        4394426,
        10843903
    ],
    [
        4394426,
        16725742,
        14785023
    ],
    [
        16725742,
        16711784,
        14905087
    ],
    [
        6116863,
        45567,
        16745409
    ],
    [
        16729383,
        16729383,
        16766623
    ],
    [
        15873618,
        8191,
        16743805
    ],
    [
        15873618,
        45567,
        16754540
    ],
    [
        4555775,
        9188338,
        16754540
    ],
    [
        9188338,
        45567,
        12779510
    ],
    [
        8191,
        15873618,
        16754540
    ],
    [
        9188338,
        9188338,
        16743805
    ],
    [
        8191,
        16729383,
        12779510
    ],
    [
        16729383,
        8191,
        16754540
    ],
    [
        4555775,
        45567,
        16766623
    ],
    [
        16711784,
        16726915,
        16734100
    ],
    [
        8191,
        8191,
        16734100
    ],
    [
        6116863,
        16729383,
        16766623
    ],
    [
        8191,
        4555775,
        16754540
    ],
    [
        45567,
        4555775,
        7763711
    ],
    [
        16711784,
        9188338,
        16734100
    ],
    [
        6116863,
        15873618,
        14905087
    ],
    [
        45567,
        16711784,
        14905087
    ],
    [
        9188338,
        4394426,
        16745409
    ],
    [
        16726915,
        4394426,
        16773519
    ],
    [
        9188338,
        6116863,
        12779510
    ],
    [
        4555775,
        3723250,
        12779510
    ],
    [
        4555775,
        3723250,
        16734100
    ],
    [
        16711784,
        8191,
        12779510
    ],
    [
        4555775,
        16726915,
        16773519
    ],
    [
        16729383,
        16729383,
        16773519
    ],
    [
        16725742,
        16725742,
        16754540
    ],
    [
        4394426,
        15873618,
        12779510
    ],
    [
        45567,
        6116863,
        16743805
    ],
    [
        4555775,
        45567,
        10843903
    ],
    [
        4555775,
        16725742,
        16743805
    ],
    [
        16711784,
        8191,
        16766623
    ],
    [
        9188338,
        6116863,
        16754540
    ],
    [
        4394426,
        16725742,
        16773519
    ],
    [
        15873618,
        9188338,
        14905087
    ],
    [
        45567,
        45567,
        14905087
    ],
    [
        45567,
        4555775,
        16766623
    ],
    [
        9188338,
        16711784,
        7763711
    ],
    [
        6116863,
        16711784,
        16753546
    ],
    [
        16711784,
        4394426,
        16754540
    ],
    [
        9188338,
        45567,
        14905087
    ],
    [
        4394426,
        15873618,
        16766623
    ],
    [
        3723250,
        8191,
        16753546
    ],
    [
        15873618,
        4555775,
        16754540
    ],
    [
        16729383,
        6116863,
        16773519
    ],
    [
        8191,
        15873618,
        16753546
    ],
    [
        16726915,
        9188338,
        16743805
    ],
    [
        4555775,
        6116863,
        14785023
    ],
    [
        8191,
        16711784,
        14905087
    ],
    [
        16726915,
        6116863,
        7763711
    ],
    [
        15873618,
        4555775,
        16773519
    ],
    [
        4555775,
        16725742,
        16745409
    ],
    [
        3723250,
        4394426,
        16734100
    ],
    [
        6116863,
        9188338,
        16754540
    ],
    [
        6116863,
        4394426,
        10843903
    ],
    [
        16725742,
        16726915,
        12779510
    ],
    [
        9188338,
        9188338,
        7763711
    ],
    [
        16725742,
        16711784,
        16745409
    ],
    [
        16725742,
        9188338,
        14785023
    ],
    [
        3723250,
        16729383,
        14905087
    ],
    [
        16725742,
        15873618,
        16754540
    ],
    [
        15873618,
        4394426,
        14905087
    ],
    [
        6116863,
        16725742,
        12779510
    ],
    [
        4394426,
        9188338,
        16734100
    ],
    [
        16711784,
        4555775,
        7763711
    ],
    [
        16725742,
        16729383,
        12779510
    ],
    [
        6116863,
        45567,
        14905087
    ],
    [
        9188338,
        6116863,
        16743805
    ],
    [
        16729383,
        16711784,
        12779510
    ],
    [
        15873618,
        16729383,
        16754540
    ],
    [
        6116863,
        3723250,
        16745409
    ],
    [
        3723250,
        3723250,
        14905087
    ],
    [
        8191,
        45567,
        7763711
    ],
    [
        16726915,
        16729383,
        16753546
    ],
    [
        16711784,
        9188338,
        16745409
    ],
    [
        15873618,
        8191,
        16766623
    ],
    [
        8191,
        16711784,
        12779510
    ],
    [
        16711784,
        16725742,
        16743805
    ],
    [
        8191,
        9188338,
        16745409
    ],
    [
        45567,
        4555775,
        16743805
    ],
    [
        4555775,
        4555775,
        14785023
    ],
    [
        16729383,
        45567,
        16743805
    ],
    [
        9188338,
        16729383,
        14785023
    ],
    [
        4555775,
        15873618,
        12779510
    ],
    [
        45567,
        9188338,
        16754540
    ],
    [
        16711784,
        4555775,
        16734100
    ],
    [
        9188338,
        16725742,
        16754540
    ],
    [
        16729383,
        15873618,
        14905087
    ],
    [
        16726915,
        15873618,
        16745409
    ],
    [
        45567,
        15873618,
        16745409
    ],
    [
        9188338,
        6116863,
        14905087
    ],
    [
        16725742,
        9188338,
        7763711
    ],
    [
        3723250,
        16726915,
        16745409
    ],
    [
        16711784,
        3723250,
        16745409
    ],
    [
        45567,
        3723250,
        16734100
    ],
    [
        6116863,
        4555775,
        16734100
    ],
    [
        16711784,
        16729383,
        16773519
    ],
    [
        6116863,
        8191,
        16766623
    ],
    [
        8191,
        45567,
        12779510
    ],
    [
        15873618,
        3723250,
        12779510
    ],
    [
        4394426,
        45567,
        14905087
    ],
    [
        16725742,
        16725742,
        16745409
    ],
    [
        16725742,
        8191,
        12779510
    ],
    [
        8191,
        8191,
        16753546
    ],
    [
        15873618,
        8191,
        16753546
    ],
    [
        16711784,
        16725742,
        16773519
    ],
    [
        4555775,
        16711784,
        16754540
    ],
    [
        4394426,
        16729383,
        14785023
    ],
    [
        9188338,
        45567,
        10843903
    ],
    [
        16725742,
        9188338,
        14905087
    ],
    [
        16711784,
        45567,
        16743805
    ],
    [
        45567,
        16725742,
        14905087
    ],
    [
        4555775,
        45567,
        16743805
    ],
    [
        4555775,
        6116863,
        7763711
    ],
    [
        9188338,
        16725742,
        14785023
    ],
    [
        16726915,
        3723250,
        16745409
    ],
    [
        6116863,
        9188338,
        12779510
    ],
    [
        16725742,
        3723250,
        16734100
    ],
    [
        8191,
        16726915,
        16766623
    ],
    [
        3723250,
        45567,
        12779510
    ],
    [
        16725742,
        3723250,
        7763711
    ],
    [
        16726915,
        16726915,
        16754540
    ],
    [
        3723250,
        16726915,
        10843903
    ],
    [
        16729383,
        6116863,
        16766623
    ],
    [
        16729383,
        16725742,
        16753546
    ],
    [
        16711784,
        16725742,
        12779510
    ],
    [
        15873618,
        16729383,
        10843903
    ],
    [
        9188338,
        3723250,
        10843903
    ],
    [
        9188338,
        16726915,
        16734100
    ],
    [
        15873618,
        4555775,
        12779510
    ],
    [
        16711784,
        9188338,
        7763711
    ],
    [
        45567,
        8191,
        16743805
    ],
    [
        4555775,
        16725742,
        16754540
    ],
    [
        16729383,
        45567,
        12779510
    ],
    [
        16711784,
        45567,
        16773519
    ],
    [
        4394426,
        9188338,
        14905087
    ],
    [
        4555775,
        4555775,
        14905087
    ],
    [
        16729383,
        8191,
        16734100
    ],
    [
        16729383,
        45567,
        16745409
    ],
    [
        15873618,
        4555775,
        14785023
    ],
    [
        16729383,
        16711784,
        16753546
    ],
    [
        9188338,
        16726915,
        16754540
    ],
    [
        4555775,
        4394426,
        7763711
    ],
    [
        16729383,
        4394426,
        12779510
    ],
    [
        16729383,
        4555775,
        16745409
    ],
    [
        15873618,
        16726915,
        7763711
    ],
    [
        4394426,
        4394426,
        14785023
    ],
    [
        15873618,
        4555775,
        7763711
    ],
    [
        4555775,
        9188338,
        14785023
    ],
    [
        15873618,
        4394426,
        12779510
    ],
    [
        8191,
        6116863,
        16743805
    ],
    [
        15873618,
        16729383,
        7763711
    ],
    [
        16726915,
        8191,
        16734100
    ],
    [
        16729383,
        4555775,
        16773519
    ],
    [
        16729383,
        9188338,
        16753546
    ],
    [
        4394426,
        16729383,
        16745409
    ],
    [
        16711784,
        16726915,
        16754540
    ],
    [
        16725742,
        3723250,
        10843903
    ],
    [
        16726915,
        6116863,
        14785023
    ],
    [
        4394426,
        16725742,
        16745409
    ],
    [
        16726915,
        4555775,
        7763711
    ],
    [
        16725742,
        4555775,
        16743805
    ],
    [
        8191,
        16711784,
        10843903
    ],
    [
        45567,
        16729383,
        16743805
    ],
    [
        4555775,
        8191,
        16766623
    ],
    [
        6116863,
        4555775,
        10843903
    ],
    [
        16729383,
        4394426,
        16754540
    ],
    [
        45567,
        16711784,
        10843903
    ],
    [
        16725742,
        9188338,
        16743805
    ],
    [
        4394426,
        4555775,
        10843903
    ],
    [
        3723250,
        16725742,
        14905087
    ],
    [
        8191,
        16711784,
        16773519
    ],
    [
        45567,
        4555775,
        16754540
    ],
    [
        16725742,
        3723250,
        16773519
    ],
    [
        8191,
        45567,
        10843903
    ],
    [
        16729383,
        16726915,
        14785023
    ],
    [
        16729383,
        6116863,
        10843903
    ],
    [
        16726915,
        16726915,
        7763711
    ],
    [
        9188338,
        16711784,
        16745409
    ],
    [
        45567,
        16729383,
        14785023
    ],
    [
        15873618,
        3723250,
        16743805
    ],
    [
        9188338,
        8191,
        12779510
    ],
    [
        15873618,
        8191,
        16773519
    ],
    [
        6116863,
        6116863,
        14785023
    ],
    [
        6116863,
        6116863,
        16734100
    ],
    [
        16726915,
        4555775,
        16773519
    ],
    [
        9188338,
        4394426,
        14905087
    ],
    [
        9188338,
        3723250,
        16754540
    ],
    [
        16725742,
        4394426,
        16743805
    ],
    [
        4394426,
        4394426,
        16734100
    ],
    [
        16726915,
        4394426,
        16753546
    ],
    [
        15873618,
        6116863,
        7763711
    ],
    [
        45567,
        45567,
        16773519
    ],
    [
        6116863,
        4555775,
        7763711
    ],
    [
        3723250,
        15873618,
        16766623
    ],
    [
        15873618,
        4394426,
        16773519
    ],
    [
        8191,
        16729383,
        7763711
    ],
    [
        16711784,
        45567,
        16745409
    ],
    [
        6116863,
        16725742,
        16745409
    ],
    [
        15873618,
        15873618,
        16754540
    ],
    [
        6116863,
        9188338,
        16743805
    ],
    [
        6116863,
        16726915,
        16734100
    ],
    [
        16725742,
        6116863,
        16745409
    ],
    [
        6116863,
        16725742,
        7763711
    ],
    [
        9188338,
        16725742,
        14905087
    ],
    [
        4555775,
        3723250,
        16743805
    ],
    [
        16725742,
        4394426,
        16745409
    ],
    [
        4394426,
        16711784,
        12779510
    ],
    [
        3723250,
        6116863,
        12779510
    ],
    [
        9188338,
        15873618,
        16745409
    ],
    [
        16711784,
        16711784,
        10843903
    ],
    [
        15873618,
        15873618,
        16734100
    ],
    [
        4555775,
        9188338,
        10843903
    ],
    [
        16729383,
        15873618,
        16773519
    ],
    [
        8191,
        16729383,
        14905087
    ],
    [
        8191,
        9188338,
        7763711
    ],
    [
        4394426,
        15873618,
        16773519
    ],
    [
        15873618,
        45567,
        16743805
    ],
    [
        3723250,
        8191,
        10843903
    ],
    [
        3723250,
        4394426,
        16773519
    ],
    [
        4555775,
        16711784,
        16743805
    ],
    [
        8191,
        16726915,
        16743805
    ],
    [
        4555775,
        3723250,
        16745409
    ],
    [
        9188338,
        8191,
        16734100
    ],
    [
        6116863,
        16711784,
        14785023
    ],
    [
        16711784,
        4394426,
        16773519
    ],
    [
        16729383,
        16711784,
        10843903
    ],
    [
        16726915,
        9188338,
        12779510
    ],
    [
        16711784,
        16725742,
        16754540
    ],
    [
        6116863,
        16729383,
        16753546
    ],
    [
        16725742,
        16729383,
        16734100
    ],
    [
        16729383,
        16711784,
        16766623
    ],
    [
        8191,
        3723250,
        12779510
    ],
    [
        45567,
        4555775,
        14785023
    ],
    [
        8191,
        3723250,
        16743805
    ],
    [
        8191,
        8191,
        14905087
    ],
    [
        16726915,
        16725742,
        16743805
    ],
    [
        16711784,
        45567,
        16766623
    ],
    [
        4394426,
        15873618,
        16753546
    ],
    [
        3723250,
        16726915,
        16773519
    ],
    [
        45567,
        6116863,
        10843903
    ],
    [
        4555775,
        8191,
        16745409
    ],
    [
        16725742,
        16711784,
        16753546
    ],
    [
        3723250,
        9188338,
        10843903
    ],
    [
        16729383,
        4555775,
        10843903
    ],
    [
        3723250,
        16726915,
        7763711
    ],
    [
        4394426,
        9188338,
        16743805
    ],
    [
        4555775,
        9188338,
        16745409
    ],
    [
        16725742,
        4555775,
        16766623
    ],
    [
        16726915,
        15873618,
        10843903
    ],
    [
        3723250,
        8191,
        7763711
    ],
    [
        4555775,
        4394426,
        16734100
    ],
    [
        6116863,
        4555775,
        16745409
    ],
    [
        16729383,
        16726915,
        14905087
    ],
    [
        8191,
        45567,
        16745409
    ],
    [
        16726915,
        6116863,
        16743805
    ],
    [
        16726915,
        16725742,
        7763711
    ],
    [
        16729383,
        45567,
        16753546
    ],
    [
        8191,
        16711784,
        16734100
    ],
    [
        45567,
        3723250,
        12779510
    ],
    [
        4555775,
        9188338,
        12779510
    ],
    [
        16729383,
        6116863,
        16754540
    ],
    [
        16711784,
        16711784,
        16753546
    ],
    [
        45567,
        15873618,
        7763711
    ],
    [
        3723250,
        16711784,
        16734100
    ],
    [
        3723250,
        8191,
        12779510
    ],
    [
        16726915,
        16726915,
        16766623
    ],
    [
        16726915,
        16725742,
        16734100
    ],
    [
        3723250,
        16729383,
        16753546
    ],
    [
        15873618,
        6116863,
        16754540
    ],
    [
        9188338,
        9188338,
        14905087
    ],
    [
        16729383,
        4555775,
        14785023
    ],
    [
        16729383,
        9188338,
        16773519
    ],
    [
        45567,
        16726915,
        16743805
    ],
    [
        15873618,
        3723250,
        14905087
    ],
    [
        6116863,
        16729383,
        14785023
    ],
    [
        16729383,
        16725742,
        12779510
    ],
    [
        4555775,
        16729383,
        16734100
    ],
    [
        16726915,
        9188338,
        16766623
    ],
    [
        16711784,
        8191,
        16754540
    ],
    [
        4555775,
        15873618,
        16753546
    ],
    [
        4555775,
        16711784,
        7763711
    ],
    [
        3723250,
        16726915,
        16754540
    ],
    [
        3723250,
        9188338,
        16743805
    ],
    [
        3723250,
        3723250,
        16766623
    ],
    [
        15873618,
        9188338,
        16754540
    ],
    [
        6116863,
        9188338,
        16753546
    ],
    [
        45567,
        9188338,
        14785023
    ],
    [
        4394426,
        16726915,
        16753546
    ],
    [
        16726915,
        16711784,
        7763711
    ],
    [
        16725742,
        3723250,
        14905087
    ],
    [
        6116863,
        8191,
        12779510
    ],
    [
        3723250,
        6116863,
        14905087
    ],
    [
        3723250,
        45567,
        7763711
    ],
    [
        16729383,
        16725742,
        7763711
    ],
    [
        9188338,
        45567,
        7763711
    ],
    [
        16725742,
        4555775,
        10843903
    ],
    [
        9188338,
        6116863,
        10843903
    ],
    [
        16726915,
        4555775,
        10843903
    ],
    [
        16729383,
        16729383,
        16753546
    ],
    [
        45567,
        4394426,
        16754540
    ],
    [
        15873618,
        45567,
        16753546
    ],
    [
        16725742,
        16725742,
        16766623
    ],
    [
        16725742,
        9188338,
        16734100
    ],
    [
        4555775,
        4394426,
        16773519
    ],
    [
        16711784,
        15873618,
        16734100
    ],
    [
        6116863,
        15873618,
        16773519
    ],
    [
        9188338,
        9188338,
        16754540
    ],
    [
        45567,
        6116863,
        16766623
    ],
    [
        4394426,
        16726915,
        7763711
    ],
    [
        16729383,
        15873618,
        16766623
    ],
    [
        16725742,
        45567,
        16754540
    ],
    [
        8191,
        4555775,
        10843903
    ],
    [
        8191,
        4394426,
        14905087
    ],
    [
        45567,
        4394426,
        14785023
    ],
    [
        45567,
        8191,
        10843903
    ],
    [
        8191,
        16726915,
        14785023
    ],
    [
        16725742,
        3723250,
        12779510
    ],
    [
        16725742,
        16726915,
        7763711
    ],
    [
        45567,
        8191,
        7763711
    ],
    [
        8191,
        16729383,
        10843903
    ],
    [
        16725742,
        6116863,
        10843903
    ],
    [
        3723250,
        16729383,
        16745409
    ],
    [
        4394426,
        4394426,
        14905087
    ],
    [
        16725742,
        15873618,
        16734100
    ],
    [
        45567,
        16711784,
        16766623
    ],
    [
        8191,
        6116863,
        12779510
    ],
    [
        16711784,
        9188338,
        16766623
    ],
    [
        9188338,
        16726915,
        16766623
    ],
    [
        9188338,
        8191,
        14785023
    ],
    [
        9188338,
        8191,
        14905087
    ],
    [
        16729383,
        16726915,
        16773519
    ],
    [
        16725742,
        4394426,
        16753546
    ],
    [
        16711784,
        16726915,
        16745409
    ],
    [
        16711784,
        16711784,
        14785023
    ],
    [
        16729383,
        16726915,
        16734100
    ],
    [
        3723250,
        16726915,
        16734100
    ],
    [
        45567,
        16729383,
        12779510
    ],
    [
        15873618,
        6116863,
        16766623
    ],
    [
        6116863,
        16726915,
        12779510
    ],
    [
        16711784,
        6116863,
        14785023
    ],
    [
        3723250,
        16711784,
        10843903
    ],
    [
        16726915,
        16729383,
        16766623
    ],
    [
        9188338,
        16729383,
        16754540
    ],
    [
        4555775,
        16729383,
        16743805
    ],
    [
        16725742,
        16726915,
        16734100
    ],
    [
        45567,
        45567,
        14785023
    ],
    [
        3723250,
        4394426,
        10843903
    ],
    [
        16725742,
        4394426,
        16734100
    ],
    [
        16726915,
        4394426,
        14785023
    ],
    [
        16726915,
        8191,
        16753546
    ],
    [
        6116863,
        4555775,
        14785023
    ],
    [
        16726915,
        16711784,
        16754540
    ],
    [
        4394426,
        4394426,
        16745409
    ],
    [
        16726915,
        6116863,
        16773519
    ],
    [
        3723250,
        6116863,
        16766623
    ],
    [
        15873618,
        16729383,
        16766623
    ],
    [
        15873618,
        16729383,
        12779510
    ],
    [
        3723250,
        16711784,
        16754540
    ],
    [
        4394426,
        45567,
        16766623
    ],
    [
        4394426,
        4555775,
        14905087
    ],
    [
        16726915,
        4394426,
        16766623
    ],
    [
        4394426,
        15873618,
        7763711
    ],
    [
        8191,
        4394426,
        16766623
    ],
    [
        16711784,
        16725742,
        16766623
    ],
    [
        16711784,
        16725742,
        7763711
    ],
    [
        45567,
        4394426,
        14905087
    ],
    [
        4555775,
        16726915,
        16766623
    ],
    [
        16725742,
        4555775,
        16745409
    ],
    [
        16725742,
        16711784,
        7763711
    ],
    [
        16711784,
        3723250,
        16734100
    ],
    [
        16725742,
        8191,
        16743805
    ],
    [
        16725742,
        15873618,
        16745409
    ],
    [
        16729383,
        3723250,
        7763711
    ],
    [
        8191,
        45567,
        16734100
    ],
    [
        9188338,
        8191,
        10843903
    ],
    [
        16711784,
        8191,
        16773519
    ],
    [
        15873618,
        16726915,
        14905087
    ],
    [
        6116863,
        45567,
        16734100
    ],
    [
        45567,
        8191,
        16766623
    ],
    [
        3723250,
        15873618,
        16734100
    ],
    [
        8191,
        8191,
        16754540
    ],
    [
        16725742,
        15873618,
        7763711
    ],
    [
        16726915,
        45567,
        12779510
    ],
    [
        6116863,
        16711784,
        16766623
    ],
    [
        16729383,
        16726915,
        16745409
    ],
    [
        45567,
        4394426,
        16773519
    ],
    [
        45567,
        15873618,
        16754540
    ],
    [
        9188338,
        4555775,
        16753546
    ],
    [
        16726915,
        8191,
        10843903
    ],
    [
        16726915,
        4555775,
        16743805
    ],
    [
        16711784,
        16729383,
        16753546
    ],
    [
        16729383,
        4394426,
        14905087
    ],
    [
        3723250,
        16711784,
        7763711
    ],
    [
        4394426,
        45567,
        16773519
    ],
    [
        4394426,
        15873618,
        16743805
    ],
    [
        45567,
        3723250,
        16745409
    ],
    [
        16725742,
        4394426,
        10843903
    ],
    [
        4394426,
        16711784,
        16743805
    ],
    [
        16726915,
        16726915,
        16745409
    ],
    [
        8191,
        9188338,
        16753546
    ],
    [
        16725742,
        6116863,
        16766623
    ],
    [
        16726915,
        16726915,
        12779510
    ],
    [
        16711784,
        16726915,
        10843903
    ],
    [
        16729383,
        3723250,
        16754540
    ],
    [
        16711784,
        45567,
        14905087
    ],
    [
        16729383,
        16725742,
        16734100
    ],
    [
        6116863,
        16725742,
        16773519
    ],
    [
        3723250,
        4555775,
        12779510
    ],
    [
        6116863,
        4394426,
        16734100
    ],
    [
        4555775,
        15873618,
        7763711
    ],
    [
        4555775,
        16726915,
        16745409
    ],
    [
        16725742,
        16711784,
        16734100
    ],
    [
        9188338,
        9188338,
        16773519
    ],
    [
        16726915,
        45567,
        16734100
    ],
    [
        15873618,
        16711784,
        10843903
    ],
    [
        6116863,
        15873618,
        14785023
    ],
    [
        16726915,
        6116863,
        16766623
    ],
    [
        16725742,
        45567,
        16743805
    ],
    [
        16725742,
        16711784,
        12779510
    ],
    [
        6116863,
        4394426,
        16773519
    ],
    [
        9188338,
        16729383,
        12779510
    ],
    [
        45567,
        16725742,
        7763711
    ],
    [
        16726915,
        15873618,
        14905087
    ],
    [
        45567,
        15873618,
        14785023
    ],
    [
        8191,
        4555775,
        16773519
    ],
    [
        8191,
        16726915,
        16753546
    ],
    [
        3723250,
        45567,
        14785023
    ],
    [
        9188338,
        16711784,
        16754540
    ],
    [
        8191,
        4394426,
        16754540
    ],
    [
        8191,
        3723250,
        7763711
    ],
    [
        8191,
        9188338,
        16773519
    ],
    [
        9188338,
        45567,
        16753546
    ],
    [
        16726915,
        45567,
        16753546
    ],
    [
        3723250,
        4394426,
        16743805
    ],
    [
        16729383,
        16711784,
        16754540
    ],
    [
        8191,
        16711784,
        7763711
    ],
    [
        16711784,
        16711784,
        7763711
    ],
    [
        16726915,
        3723250,
        16773519
    ],
    [
        8191,
        8191,
        16745409
    ],
    [
        16726915,
        3723250,
        10843903
    ],
    [
        9188338,
        16711784,
        16773519
    ],
    [
        45567,
        3723250,
        16773519
    ],
    [
        16711784,
        16725742,
        10843903
    ],
    [
        4555775,
        16729383,
        16745409
    ],
    [
        16726915,
        16729383,
        16773519
    ],
    [
        4555775,
        3723250,
        7763711
    ],
    [
        3723250,
        4394426,
        16754540
    ],
    [
        3723250,
        4394426,
        14785023
    ],
    [
        16726915,
        9188338,
        16753546
    ],
    [
        15873618,
        9188338,
        7763711
    ],
    [
        8191,
        45567,
        16743805
    ],
    [
        16726915,
        16726915,
        14905087
    ],
    [
        8191,
        4394426,
        12779510
    ],
    [
        15873618,
        6116863,
        12779510
    ],
    [
        4555775,
        4555775,
        10843903
    ],
    [
        9188338,
        4555775,
        14905087
    ],
    [
        8191,
        4555775,
        12779510
    ],
    [
        3723250,
        16726915,
        12779510
    ],
    [
        9188338,
        8191,
        16745409
    ],
    [
        16726915,
        6116863,
        10843903
    ],
    [
        45567,
        15873618,
        16734100
    ],
    [
        3723250,
        6116863,
        14785023
    ],
    [
        8191,
        3723250,
        16745409
    ],
    [
        4555775,
        16726915,
        14785023
    ],
    [
        15873618,
        9188338,
        16743805
    ],
    [
        9188338,
        16729383,
        16745409
    ],
    [
        9188338,
        4394426,
        14785023
    ],
    [
        45567,
        8191,
        16734100
    ],
    [
        8191,
        16725742,
        12779510
    ],
    [
        4555775,
        45567,
        7763711
    ],
    [
        16711784,
        45567,
        16734100
    ],
    [
        15873618,
        16726915,
        16773519
    ],
    [
        9188338,
        16726915,
        16753546
    ],
    [
        16726915,
        9188338,
        16745409
    ],
    [
        15873618,
        4555775,
        16734100
    ],
    [
        15873618,
        8191,
        16734100
    ],
    [
        4555775,
        9188338,
        7763711
    ],
    [
        4394426,
        16711784,
        14785023
    ],
    [
        15873618,
        16726915,
        12779510
    ],
    [
        15873618,
        4555775,
        16753546
    ],
    [
        16729383,
        15873618,
        16734100
    ],
    [
        15873618,
        45567,
        14785023
    ],
    [
        45567,
        16711784,
        7763711
    ],
    [
        4394426,
        6116863,
        16754540
    ],
    [
        16729383,
        16711784,
        14785023
    ],
    [
        45567,
        9188338,
        16743805
    ],
    [
        4555775,
        4555775,
        16745409
    ],
    [
        9188338,
        16725742,
        16773519
    ],
    [
        16729383,
        4555775,
        16743805
    ],
    [
        6116863,
        8191,
        10843903
    ],
    [
        16725742,
        16729383,
        16754540
    ],
    [
        16725742,
        16729383,
        16773519
    ],
    [
        16726915,
        3723250,
        16753546
    ],
    [
        16711784,
        6116863,
        16753546
    ],
    [
        9188338,
        16711784,
        16734100
    ],
    [
        9188338,
        15873618,
        16766623
    ],
    [
        4555775,
        3723250,
        14785023
    ],
    [
        6116863,
        8191,
        16743805
    ],
    [
        3723250,
        3723250,
        16754540
    ],
    [
        4394426,
        4555775,
        16734100
    ],
    [
        4555775,
        16729383,
        10843903
    ],
    [
        9188338,
        15873618,
        14785023
    ],
    [
        3723250,
        3723250,
        10843903
    ],
    [
        16726915,
        4555775,
        16753546
    ],
    [
        16729383,
        15873618,
        16743805
    ],
    [
        8191,
        45567,
        14785023
    ],
    [
        45567,
        3723250,
        14785023
    ],
    [
        45567,
        16711784,
        16743805
    ],
    [
        16726915,
        6116863,
        16754540
    ],
    [
        8191,
        45567,
        14905087
    ],
    [
        4555775,
        15873618,
        16773519
    ],
    [
        4555775,
        45567,
        16753546
    ],
    [
        4394426,
        4555775,
        16753546
    ],
    [
        16711784,
        4555775,
        16773519
    ],
    [
        3723250,
        16729383,
        16766623
    ],
    [
        16729383,
        4394426,
        16753546
    ],
    [
        16725742,
        16726915,
        16753546
    ],
    [
        6116863,
        45567,
        16766623
    ],
    [
        45567,
        4394426,
        16734100
    ],
    [
        45567,
        15873618,
        12779510
    ],
    [
        16729383,
        45567,
        14785023
    ],
    [
        16726915,
        3723250,
        14905087
    ],
    [
        16726915,
        45567,
        16745409
    ],
    [
        3723250,
        4555775,
        14785023
    ],
    [
        9188338,
        9188338,
        16745409
    ],
    [
        6116863,
        16725742,
        16734100
    ],
    [
        16725742,
        3723250,
        16743805
    ],
    [
        16729383,
        4555775,
        16754540
    ],
    [
        45567,
        16726915,
        12779510
    ],
    [
        4555775,
        3723250,
        16766623
    ],
    [
        16729383,
        6116863,
        14785023
    ],
    [
        16726915,
        45567,
        10843903
    ],
    [
        4394426,
        6116863,
        16734100
    ],
    [
        4555775,
        16725742,
        14905087
    ],
    [
        45567,
        16729383,
        16753546
    ],
    [
        9188338,
        6116863,
        14785023
    ],
    [
        16711784,
        16711784,
        16754540
    ],
    [
        6116863,
        9188338,
        10843903
    ],
    [
        45567,
        8191,
        14785023
    ],
    [
        15873618,
        16726915,
        16745409
    ],
    [
        3723250,
        16725742,
        16745409
    ],
    [
        16725742,
        4394426,
        12779510
    ],
    [
        8191,
        16726915,
        10843903
    ],
    [
        9188338,
        3723250,
        16773519
    ],
    [
        16726915,
        16711784,
        14785023
    ],
    [
        6116863,
        16711784,
        7763711
    ],
    [
        16711784,
        16729383,
        16743805
    ],
    [
        16711784,
        8191,
        10843903
    ],
    [
        8191,
        9188338,
        12779510
    ],
    [
        3723250,
        45567,
        16743805
    ],
    [
        16711784,
        16729383,
        12779510
    ],
    [
        4555775,
        8191,
        14785023
    ],
    [
        4394426,
        4394426,
        16743805
    ],
    [
        6116863,
        8191,
        16734100
    ],
    [
        16726915,
        8191,
        16754540
    ],
    [
        16711784,
        6116863,
        7763711
    ],
    [
        4394426,
        9188338,
        16773519
    ],
    [
        6116863,
        9188338,
        16745409
    ],
    [
        4555775,
        16711784,
        14905087
    ],
    [
        3723250,
        16726915,
        14905087
    ],
    [
        9188338,
        8191,
        16743805
    ],
    [
        15873618,
        6116863,
        14785023
    ],
    [
        16725742,
        8191,
        16773519
    ],
    [
        4394426,
        4555775,
        12779510
    ],
    [
        16726915,
        4394426,
        16743805
    ],
    [
        16725742,
        3723250,
        16745409
    ],
    [
        16725742,
        4555775,
        14905087
    ],
    [
        16729383,
        9188338,
        16743805
    ],
    [
        45567,
        9188338,
        16745409
    ],
    [
        8191,
        16711784,
        16754540
    ],
    [
        4394426,
        9188338,
        12779510
    ],
    [
        4394426,
        4394426,
        7763711
    ],
    [
        8191,
        16726915,
        16734100
    ],
    [
        9188338,
        16711784,
        16766623
    ],
    [
        9188338,
        15873618,
        7763711
    ],
    [
        16725742,
        15873618,
        14785023
    ],
    [
        4394426,
        16725742,
        16753546
    ],
    [
        3723250,
        4555775,
        14905087
    ],
    [
        15873618,
        15873618,
        16745409
    ],
    [
        16711784,
        16726915,
        14785023
    ],
    [
        3723250,
        16729383,
        14785023
    ],
    [
        45567,
        16725742,
        10843903
    ],
    [
        15873618,
        16729383,
        14785023
    ],
    [
        6116863,
        16726915,
        16745409
    ],
    [
        4555775,
        16729383,
        7763711
    ],
    [
        6116863,
        16729383,
        16745409
    ],
    [
        6116863,
        16726915,
        16743805
    ],
    [
        4394426,
        16726915,
        16745409
    ],
    [
        4394426,
        16726915,
        10843903
    ],
    [
        4555775,
        8191,
        7763711
    ],
    [
        16726915,
        16725742,
        10843903
    ],
    [
        3723250,
        45567,
        16773519
    ],
    [
        3723250,
        9188338,
        7763711
    ],
    [
        16729383,
        16726915,
        10843903
    ],
    [
        4555775,
        8191,
        16734100
    ],
    [
        8191,
        16725742,
        7763711
    ],
    [
        15873618,
        6116863,
        16773519
    ],
    [
        3723250,
        16726915,
        16766623
    ],
    [
        4555775,
        16711784,
        16745409
    ],
    [
        8191,
        6116863,
        14785023
    ],
    [
        4555775,
        15873618,
        16734100
    ],
    [
        8191,
        9188338,
        14905087
    ],
    [
        9188338,
        45567,
        16754540
    ],
    [
        45567,
        8191,
        16745409
    ],
    [
        16726915,
        16726915,
        16743805
    ],
    [
        15873618,
        15873618,
        14905087
    ],
    [
        3723250,
        9188338,
        16766623
    ],
    [
        3723250,
        16711784,
        16743805
    ],
    [
        16711784,
        16711784,
        12779510
    ],
    [
        16711784,
        16711784,
        16743805
    ],
    [
        15873618,
        4555775,
        10843903
    ],
    [
        9188338,
        3723250,
        14785023
    ],
    [
        6116863,
        3723250,
        16753546
    ],
    [
        45567,
        16729383,
        16773519
    ],
    [
        16729383,
        16729383,
        16734100
    ],
    [
        45567,
        8191,
        16753546
    ],
    [
        6116863,
        16711784,
        14905087
    ],
    [
        4394426,
        16725742,
        14905087
    ],
    [
        3723250,
        16729383,
        10843903
    ],
    [
        15873618,
        8191,
        16754540
    ],
    [
        15873618,
        4394426,
        16766623
    ],
    [
        6116863,
        16711784,
        12779510
    ],
    [
        15873618,
        16725742,
        14785023
    ],
    [
        4394426,
        4555775,
        14785023
    ],
    [
        45567,
        3723250,
        16754540
    ],
    [
        16711784,
        16729383,
        16766623
    ],
    [
        15873618,
        4394426,
        16734100
    ],
    [
        16729383,
        16726915,
        7763711
    ],
    [
        4394426,
        45567,
        16743805
    ],
    [
        15873618,
        3723250,
        16773519
    ],
    [
        16729383,
        45567,
        14905087
    ],
    [
        4394426,
        16729383,
        16773519
    ],
    [
        16729383,
        4555775,
        12779510
    ],
    [
        6116863,
        16726915,
        16773519
    ],
    [
        15873618,
        9188338,
        14785023
    ],
    [
        45567,
        16726915,
        16773519
    ],
    [
        4555775,
        4394426,
        10843903
    ],
    [
        4555775,
        15873618,
        14785023
    ],
    [
        4555775,
        4394426,
        16754540
    ],
    [
        16725742,
        16711784,
        16766623
    ],
    [
        3723250,
        16729383,
        16773519
    ],
    [
        45567,
        9188338,
        16766623
    ],
    [
        15873618,
        9188338,
        16766623
    ],
    [
        16711784,
        8191,
        14905087
    ],
    [
        16725742,
        15873618,
        16773519
    ],
    [
        16711784,
        16726915,
        7763711
    ],
    [
        6116863,
        45567,
        16773519
    ],
    [
        4555775,
        15873618,
        16766623
    ],
    [
        8191,
        16711784,
        16753546
    ],
    [
        4555775,
        6116863,
        16754540
    ],
    [
        15873618,
        3723250,
        16754540
    ],
    [
        6116863,
        3723250,
        16754540
    ],
    [
        3723250,
        8191,
        16754540
    ],
    [
        16725742,
        8191,
        16745409
    ],
    [
        16726915,
        4394426,
        10843903
    ],
    [
        9188338,
        4555775,
        16773519
    ],
    [
        16729383,
        8191,
        14785023
    ],
    [
        16729383,
        4394426,
        16773519
    ],
    [
        9188338,
        16729383,
        14905087
    ],
    [
        45567,
        45567,
        16754540
    ],
    [
        3723250,
        16725742,
        14785023
    ],
    [
        6116863,
        16729383,
        7763711
    ],
    [
        16726915,
        15873618,
        7763711
    ],
    [
        3723250,
        45567,
        16745409
    ],
    [
        4394426,
        4394426,
        10843903
    ],
    [
        4555775,
        4394426,
        16753546
    ],
    [
        16711784,
        3723250,
        16754540
    ],
    [
        16711784,
        9188338,
        16754540
    ],
    [
        16711784,
        4394426,
        16745409
    ],
    [
        45567,
        9188338,
        10843903
    ],
    [
        9188338,
        16725742,
        16743805
    ],
    [
        4555775,
        4555775,
        7763711
    ],
    [
        9188338,
        16726915,
        16743805
    ],
    [
        6116863,
        16729383,
        16743805
    ],
    [
        9188338,
        16725742,
        16734100
    ],
    [
        16711784,
        4555775,
        16754540
    ],
    [
        15873618,
        8191,
        12779510
    ],
    [
        3723250,
        4555775,
        16773519
    ],
    [
        16711784,
        16729383,
        14905087
    ],
    [
        4394426,
        4555775,
        16743805
    ],
    [
        8191,
        4394426,
        16743805
    ],
    [
        9188338,
        16726915,
        16745409
    ],
    [
        3723250,
        16726915,
        16743805
    ],
    [
        45567,
        4555775,
        16753546
    ],
    [
        8191,
        6116863,
        16753546
    ],
    [
        4555775,
        4555775,
        16766623
    ],
    [
        8191,
        16711784,
        14785023
    ],
    [
        16729383,
        15873618,
        14785023
    ],
    [
        3723250,
        4555775,
        7763711
    ],
    [
        4394426,
        8191,
        16743805
    ],
    [
        16726915,
        3723250,
        12779510
    ],
    [
        6116863,
        9188338,
        16766623
    ],
    [
        9188338,
        3723250,
        7763711
    ],
    [
        4555775,
        4555775,
        12779510
    ],
    [
        16726915,
        16729383,
        14905087
    ],
    [
        45567,
        45567,
        16766623
    ],
    [
        3723250,
        16729383,
        16754540
    ],
    [
        6116863,
        16711784,
        16743805
    ],
    [
        3723250,
        8191,
        16743805
    ],
    [
        6116863,
        3723250,
        16766623
    ],
    [
        16726915,
        15873618,
        16754540
    ],
    [
        16729383,
        45567,
        10843903
    ],
    [
        16711784,
        3723250,
        14785023
    ],
    [
        16711784,
        4555775,
        10843903
    ],
    [
        4555775,
        9188338,
        14905087
    ],
    [
        15873618,
        4394426,
        16753546
    ],
    [
        4555775,
        16711784,
        16773519
    ],
    [
        9188338,
        16729383,
        16743805
    ],
    [
        4394426,
        16726915,
        16766623
    ],
    [
        8191,
        9188338,
        16766623
    ],
    [
        16725742,
        45567,
        16734100
    ],
    [
        8191,
        8191,
        7763711
    ],
    [
        16711784,
        6116863,
        16743805
    ],
    [
        4394426,
        3723250,
        7763711
    ],
    [
        16726915,
        8191,
        7763711
    ],
    [
        16726915,
        16725742,
        14905087
    ],
    [
        16711784,
        4555775,
        16766623
    ],
    [
        8191,
        15873618,
        16773519
    ],
    [
        45567,
        4555775,
        10843903
    ],
    [
        9188338,
        6116863,
        16766623
    ],
    [
        4555775,
        6116863,
        12779510
    ],
    [
        6116863,
        16711784,
        16745409
    ],
    [
        16729383,
        4394426,
        16766623
    ],
    [
        45567,
        4394426,
        10843903
    ],
    [
        16726915,
        45567,
        16754540
    ],
    [
        16726915,
        3723250,
        7763711
    ],
    [
        9188338,
        45567,
        16766623
    ],
    [
        4394426,
        8191,
        16753546
    ],
    [
        16726915,
        16726915,
        10843903
    ],
    [
        16729383,
        3723250,
        14905087
    ],
    [
        6116863,
        16726915,
        14905087
    ],
    [
        8191,
        15873618,
        16766623
    ],
    [
        16725742,
        6116863,
        16773519
    ],
    [
        8191,
        4555775,
        7763711
    ],
    [
        4555775,
        4555775,
        16754540
    ],
    [
        9188338,
        4555775,
        12779510
    ],
    [
        45567,
        3723250,
        16743805
    ],
    [
        16726915,
        45567,
        16743805
    ],
    [
        16711784,
        9188338,
        10843903
    ],
    [
        16726915,
        16729383,
        16754540
    ],
    [
        4555775,
        16726915,
        16743805
    ],
    [
        6116863,
        9188338,
        7763711
    ],
    [
        4394426,
        3723250,
        10843903
    ],
    [
        16725742,
        16726915,
        16773519
    ],
    [
        6116863,
        9188338,
        14905087
    ],
    [
        15873618,
        8191,
        10843903
    ],
    [
        4555775,
        6116863,
        16773519
    ],
    [
        16711784,
        4555775,
        16753546
    ],
    [
        6116863,
        8191,
        14785023
    ],
    [
        4555775,
        4394426,
        16743805
    ],
    [
        16726915,
        6116863,
        12779510
    ],
    [
        8191,
        4394426,
        10843903
    ],
    [
        4394426,
        16725742,
        16754540
    ],
    [
        4394426,
        15873618,
        14905087
    ],
    [
        3723250,
        4555775,
        16753546
    ],
    [
        16726915,
        16725742,
        16766623
    ],
    [
        6116863,
        4394426,
        16745409
    ],
    [
        6116863,
        4555775,
        16743805
    ],
    [
        6116863,
        3723250,
        7763711
    ],
    [
        45567,
        4555775,
        16773519
    ],
    [
        8191,
        16726915,
        16745409
    ],
    [
        16726915,
        3723250,
        16734100
    ],
    [
        3723250,
        3723250,
        14785023
    ],
    [
        15873618,
        3723250,
        14785023
    ],
    [
        16729383,
        9188338,
        16745409
    ],
    [
        8191,
        6116863,
        16734100
    ],
    [
        6116863,
        6116863,
        16745409
    ],
    [
        16729383,
        4394426,
        14785023
    ],
    [
        3723250,
        15873618,
        14905087
    ],
    [
        16726915,
        6116863,
        14905087
    ],
    [
        16729383,
        15873618,
        12779510
    ],
    [
        45567,
        16726915,
        7763711
    ],
    [
        16725742,
        6116863,
        16754540
    ],
    [
        16729383,
        3723250,
        16773519
    ],
    [
        9188338,
        15873618,
        12779510
    ],
    [
        8191,
        4394426,
        16753546
    ],
    [
        3723250,
        9188338,
        16773519
    ],
    [
        16729383,
        16725742,
        10843903
    ],
    [
        4555775,
        6116863,
        16743805
    ],
    [
        16725742,
        8191,
        16754540
    ],
    [
        16729383,
        4555775,
        16734100
    ],
    [
        3723250,
        9188338,
        14785023
    ],
    [
        16725742,
        16729383,
        10843903
    ],
    [
        16711784,
        16726915,
        14905087
    ],
    [
        45567,
        6116863,
        16753546
    ],
    [
        8191,
        16729383,
        16754540
    ],
    [
        16726915,
        16729383,
        16734100
    ],
    [
        15873618,
        16711784,
        16773519
    ],
    [
        16725742,
        9188338,
        16754540
    ],
    [
        4394426,
        9188338,
        7763711
    ],
    [
        6116863,
        15873618,
        16766623
    ],
    [
        4555775,
        15873618,
        16743805
    ],
    [
        15873618,
        16711784,
        16753546
    ],
    [
        6116863,
        45567,
        16743805
    ],
    [
        16729383,
        6116863,
        16753546
    ],
    [
        4555775,
        4394426,
        12779510
    ],
    [
        16729383,
        8191,
        16773519
    ],
    [
        4555775,
        16726915,
        16754540
    ],
    [
        15873618,
        3723250,
        16766623
    ],
    [
        4555775,
        16729383,
        14905087
    ],
    [
        16729383,
        6116863,
        16743805
    ],
    [
        8191,
        4394426,
        16734100
    ],
    [
        4394426,
        45567,
        16734100
    ],
    [
        8191,
        4555775,
        14905087
    ],
    [
        16711784,
        9188338,
        16743805
    ],
    [
        8191,
        16725742,
        16734100
    ],
    [
        3723250,
        6116863,
        16734100
    ],
    [
        4555775,
        45567,
        12779510
    ],
    [
        9188338,
        16711784,
        12779510
    ],
    [
        16711784,
        15873618,
        14905087
    ],
    [
        4394426,
        9188338,
        10843903
    ],
    [
        3723250,
        4394426,
        12779510
    ],
    [
        9188338,
        16726915,
        10843903
    ],
    [
        8191,
        16725742,
        16743805
    ],
    [
        9188338,
        6116863,
        16773519
    ],
    [
        16729383,
        3723250,
        16743805
    ],
    [
        4394426,
        16711784,
        7763711
    ],
    [
        4394426,
        4555775,
        16745409
    ],
    [
        9188338,
        16711784,
        14905087
    ],
    [
        9188338,
        6116863,
        16753546
    ],
    [
        4394426,
        8191,
        10843903
    ],
    [
        3723250,
        4394426,
        7763711
    ],
    [
        15873618,
        4555775,
        14905087
    ],
    [
        9188338,
        16726915,
        14905087
    ],
    [
        4394426,
        16711784,
        10843903
    ],
    [
        4394426,
        16729383,
        7763711
    ],
    [
        16726915,
        16725742,
        16754540
    ],
    [
        4394426,
        6116863,
        10843903
    ],
    [
        8191,
        16729383,
        16773519
    ],
    [
        16711784,
        16725742,
        16753546
    ],
    [
        9188338,
        16729383,
        16773519
    ],
    [
        16726915,
        16729383,
        7763711
    ],
    [
        4555775,
        16726915,
        7763711
    ],
    [
        3723250,
        3723250,
        16773519
    ],
    [
        15873618,
        16726915,
        16753546
    ],
    [
        6116863,
        45567,
        10843903
    ],
    [
        16726915,
        6116863,
        16745409
    ],
    [
        16711784,
        3723250,
        14905087
    ],
    [
        16725742,
        4394426,
        16773519
    ],
    [
        3723250,
        45567,
        16734100
    ],
    [
        4555775,
        4394426,
        14905087
    ],
    [
        15873618,
        15873618,
        16766623
    ],
    [
        4555775,
        16729383,
        14785023
    ],
    [
        45567,
        16726915,
        10843903
    ],
    [
        45567,
        3723250,
        16753546
    ],
    [
        4394426,
        45567,
        12779510
    ],
    [
        16729383,
        16729383,
        14785023
    ],
    [
        6116863,
        45567,
        14785023
    ],
    [
        4394426,
        4555775,
        16766623
    ],
    [
        4555775,
        9188338,
        16753546
    ],
    [
        8191,
        6116863,
        14905087
    ],
    [
        4555775,
        3723250,
        14905087
    ],
    [
        8191,
        6116863,
        16754540
    ],
    [
        16711784,
        45567,
        7763711
    ],
    [
        16725742,
        8191,
        14785023
    ],
    [
        45567,
        16726915,
        16766623
    ],
    [
        4394426,
        45567,
        16754540
    ],
    [
        6116863,
        15873618,
        16753546
    ],
    [
        6116863,
        16725742,
        14785023
    ],
    [
        16711784,
        4394426,
        7763711
    ],
    [
        4555775,
        16711784,
        10843903
    ],
    [
        16725742,
        45567,
        16773519
    ],
    [
        16729383,
        9188338,
        16734100
    ],
    [
        16729383,
        6116863,
        7763711
    ],
    [
        16729383,
        16711784,
        14905087
    ],
    [
        3723250,
        16726915,
        16753546
    ],
    [
        15873618,
        16711784,
        16734100
    ],
    [
        16726915,
        16725742,
        16773519
    ],
    [
        16729383,
        16711784,
        7763711
    ],
    [
        45567,
        15873618,
        10843903
    ],
    [
        9188338,
        3723250,
        14905087
    ],
    [
        16725742,
        8191,
        16753546
    ],
    [
        3723250,
        4394426,
        14905087
    ],
    [
        6116863,
        16725742,
        10843903
    ],
    [
        16726915,
        16711784,
        16773519
    ],
    [
        16711784,
        4394426,
        12779510
    ],
    [
        16729383,
        4555775,
        7763711
    ],
    [
        9188338,
        16711784,
        16753546
    ],
    [
        8191,
        16729383,
        16753546
    ],
    [
        16725742,
        16729383,
        14905087
    ],
    [
        4394426,
        16711784,
        16745409
    ],
    [
        9188338,
        4555775,
        16734100
    ],
    [
        16729383,
        8191,
        16745409
    ],
    [
        16726915,
        16725742,
        16745409
    ],
    [
        16726915,
        8191,
        16766623
    ],
    [
        16725742,
        15873618,
        14905087
    ],
    [
        16725742,
        45567,
        12779510
    ],
    [
        9188338,
        6116863,
        7763711
    ],
    [
        6116863,
        4394426,
        16754540
    ],
    [
        15873618,
        45567,
        7763711
    ],
    [
        4555775,
        16725742,
        16753546
    ],
    [
        15873618,
        4394426,
        10843903
    ],
    [
        45567,
        6116863,
        7763711
    ],
    [
        4394426,
        9188338,
        16766623
    ],
    [
        16729383,
        4555775,
        14905087
    ],
    [
        6116863,
        4555775,
        16766623
    ],
    [
        16729383,
        8191,
        16766623
    ],
    [
        8191,
        9188338,
        16754540
    ],
    [
        16726915,
        9188338,
        14785023
    ],
    [
        4394426,
        6116863,
        16745409
    ],
    [
        16729383,
        15873618,
        10843903
    ],
    [
        45567,
        3723250,
        14905087
    ],
    [
        3723250,
        4555775,
        16745409
    ],
    [
        45567,
        6116863,
        14785023
    ],
    [
        16729383,
        9188338,
        14905087
    ],
    [
        45567,
        16711784,
        14785023
    ],
    [
        16725742,
        9188338,
        16773519
    ],
    [
        16711784,
        15873618,
        16743805
    ],
    [
        15873618,
        16725742,
        16743805
    ],
    [
        6116863,
        15873618,
        16745409
    ],
    [
        45567,
        16711784,
        12779510
    ],
    [
        3723250,
        15873618,
        14785023
    ],
    [
        9188338,
        16729383,
        7763711
    ],
    [
        4394426,
        9188338,
        16753546
    ],
    [
        16725742,
        4394426,
        7763711
    ],
    [
        8191,
        16726915,
        12779510
    ],
    [
        4394426,
        16725742,
        16734100
    ],
    [
        6116863,
        6116863,
        16753546
    ],
    [
        9188338,
        4394426,
        16766623
    ],
    [
        16711784,
        16711784,
        16745409
    ],
    [
        45567,
        9188338,
        16753546
    ],
    [
        16711784,
        9188338,
        16753546
    ],
    [
        15873618,
        16725742,
        16773519
    ],
    [
        6116863,
        4394426,
        14785023
    ],
    [
        4394426,
        6116863,
        16766623
    ],
    [
        4555775,
        9188338,
        16773519
    ],
    [
        8191,
        4555775,
        14785023
    ],
    [
        16729383,
        8191,
        10843903
    ],
    [
        8191,
        16725742,
        10843903
    ],
    [
        16711784,
        15873618,
        16754540
    ],
    [
        16729383,
        16726915,
        16753546
    ],
    [
        4394426,
        8191,
        16745409
    ],
    [
        4555775,
        4394426,
        16745409
    ],
    [
        8191,
        4555775,
        16734100
    ],
    [
        4555775,
        16711784,
        14785023
    ],
    [
        16725742,
        16725742,
        16773519
    ],
    [
        8191,
        45567,
        16753546
    ],
    [
        4394426,
        45567,
        14785023
    ],
    [
        4394426,
        16729383,
        10843903
    ],
    [
        4394426,
        16711784,
        14905087
    ],
    [
        16725742,
        16726915,
        16766623
    ],
    [
        4555775,
        16729383,
        12779510
    ],
    [
        9188338,
        15873618,
        16753546
    ],
    [
        16726915,
        4555775,
        16734100
    ],
    [
        4394426,
        16726915,
        12779510
    ],
    [
        8191,
        16729383,
        16734100
    ],
    [
        16711784,
        3723250,
        16773519
    ],
    [
        8191,
        6116863,
        10843903
    ],
    [
        8191,
        8191,
        10843903
    ],
    [
        6116863,
        4555775,
        16754540
    ],
    [
        16726915,
        9188338,
        16773519
    ],
    [
        4394426,
        9188338,
        16745409
    ],
    [
        45567,
        16711784,
        16745409
    ],
    [
        4394426,
        15873618,
        10843903
    ],
    [
        4555775,
        16711784,
        16753546
    ],
    [
        15873618,
        15873618,
        14785023
    ],
    [
        16725742,
        15873618,
        10843903
    ],
    [
        15873618,
        9188338,
        10843903
    ],
    [
        15873618,
        9188338,
        16745409
    ],
    [
        4555775,
        6116863,
        16745409
    ],
    [
        16726915,
        15873618,
        12779510
    ],
    [
        15873618,
        16711784,
        16743805
    ],
    [
        4555775,
        16711784,
        16766623
    ],
    [
        4555775,
        16725742,
        16773519
    ],
    [
        15873618,
        16725742,
        10843903
    ],
    [
        45567,
        9188338,
        16773519
    ],
    [
        16725742,
        15873618,
        16766623
    ],
    [
        16726915,
        9188338,
        10843903
    ],
    [
        6116863,
        6116863,
        16773519
    ],
    [
        16725742,
        16729383,
        16766623
    ],
    [
        16729383,
        15873618,
        16745409
    ],
    [
        3723250,
        9188338,
        16734100
    ],
    [
        16725742,
        16726915,
        10843903
    ],
    [
        15873618,
        4394426,
        16743805
    ],
    [
        3723250,
        45567,
        10843903
    ],
    [
        16726915,
        3723250,
        16743805
    ],
    [
        4394426,
        16711784,
        16773519
    ],
    [
        16725742,
        6116863,
        14785023
    ],
    [
        8191,
        15873618,
        16745409
    ],
    [
        16726915,
        4555775,
        16745409
    ],
    [
        4394426,
        9188338,
        14785023
    ],
    [
        9188338,
        16711784,
        16743805
    ],
    [
        15873618,
        16725742,
        16766623
    ],
    [
        6116863,
        16729383,
        14905087
    ],
    [
        16711784,
        15873618,
        16753546
    ],
    [
        4394426,
        3723250,
        16734100
    ],
    [
        16729383,
        8191,
        16753546
    ],
    [
        16711784,
        45567,
        16754540
    ],
    [
        4555775,
        16725742,
        12779510
    ],
    [
        8191,
        16711784,
        16745409
    ],
    [
        16725742,
        45567,
        14785023
    ],
    [
        4555775,
        3723250,
        16754540
    ],
    [
        45567,
        45567,
        12779510
    ],
    [
        16729383,
        16729383,
        16754540
    ],
    [
        6116863,
        8191,
        14905087
    ],
    [
        6116863,
        3723250,
        16734100
    ],
    [
        15873618,
        16725742,
        16745409
    ],
    [
        15873618,
        3723250,
        16734100
    ],
    [
        16711784,
        4394426,
        14785023
    ],
    [
        9188338,
        3723250,
        16766623
    ],
    [
        16711784,
        6116863,
        16745409
    ],
    [
        4394426,
        3723250,
        16754540
    ],
    [
        9188338,
        3723250,
        16745409
    ],
    [
        15873618,
        8191,
        14785023
    ],
    [
        16726915,
        45567,
        7763711
    ],
    [
        3723250,
        3723250,
        16743805
    ],
    [
        16726915,
        16726915,
        14785023
    ],
    [
        45567,
        8191,
        16773519
    ],
    [
        16729383,
        16729383,
        16745409
    ],
    [
        16726915,
        8191,
        16743805
    ],
    [
        15873618,
        4394426,
        7763711
    ],
    [
        4555775,
        45567,
        16754540
    ],
    [
        3723250,
        4555775,
        16766623
    ],
    [
        15873618,
        16725742,
        16753546
    ],
    [
        45567,
        15873618,
        14905087
    ],
    [
        4555775,
        6116863,
        14905087
    ],
    [
        8191,
        15873618,
        12779510
    ],
    [
        45567,
        16725742,
        16766623
    ],
    [
        3723250,
        8191,
        16734100
    ],
    [
        4394426,
        16726915,
        16734100
    ],
    [
        3723250,
        9188338,
        14905087
    ],
    [
        4555775,
        16725742,
        14785023
    ],
    [
        15873618,
        6116863,
        16753546
    ],
    [
        8191,
        16725742,
        16773519
    ],
    [
        4394426,
        15873618,
        16745409
    ],
    [
        16729383,
        6116863,
        12779510
    ],
    [
        4555775,
        4555775,
        16734100
    ],
    [
        4394426,
        4394426,
        16753546
    ],
    [
        45567,
        16726915,
        16745409
    ],
    [
        3723250,
        6116863,
        7763711
    ],
    [
        3723250,
        16725742,
        16734100
    ],
    [
        16729383,
        16725742,
        14785023
    ],
    [
        16711784,
        15873618,
        14785023
    ],
    [
        16711784,
        8191,
        16753546
    ],
    [
        16711784,
        15873618,
        16773519
    ],
    [
        45567,
        16729383,
        16754540
    ],
    [
        45567,
        45567,
        16753546
    ],
    [
        9188338,
        15873618,
        10843903
    ],
    [
        15873618,
        16711784,
        7763711
    ],
    [
        16725742,
        4555775,
        16753546
    ],
    [
        45567,
        16726915,
        16734100
    ],
    [
        3723250,
        16711784,
        14785023
    ],
    [
        9188338,
        4394426,
        10843903
    ],
    [
        16711784,
        6116863,
        14905087
    ],
    [
        8191,
        9188338,
        14785023
    ],
    [
        16725742,
        45567,
        7763711
    ],
    [
        4394426,
        16729383,
        16766623
    ],
    [
        6116863,
        4394426,
        16743805
    ],
    [
        16726915,
        15873618,
        16743805
    ],
    [
        4394426,
        8191,
        16754540
    ],
    [
        9188338,
        8191,
        16766623
    ],
    [
        16725742,
        4555775,
        14785023
    ],
    [
        16711784,
        16729383,
        7763711
    ],
    [
        6116863,
        16726915,
        16766623
    ],
    [
        6116863,
        16711784,
        16754540
    ],
    [
        15873618,
        16725742,
        14905087
    ],
    [
        6116863,
        9188338,
        14785023
    ],
    [
        6116863,
        4555775,
        14905087
    ],
    [
        16711784,
        4555775,
        14785023
    ],
    [
        8191,
        16725742,
        16745409
    ],
    [
        16725742,
        4394426,
        16754540
    ],
    [
        15873618,
        8191,
        16745409
    ],
    [
        3723250,
        4555775,
        16743805
    ],
    [
        9188338,
        9188338,
        12779510
    ],
    [
        16726915,
        16725742,
        12779510
    ],
    [
        16726915,
        16729383,
        10843903
    ],
    [
        16729383,
        16725742,
        16745409
    ],
    [
        3723250,
        16729383,
        12779510
    ],
    [
        45567,
        45567,
        7763711
    ],
    [
        9188338,
        16726915,
        14785023
    ],
    [
        9188338,
        45567,
        16734100
    ],
    [
        16711784,
        16726915,
        16753546
    ],
    [
        45567,
        3723250,
        10843903
    ],
    [
        45567,
        6116863,
        16734100
    ],
    [
        6116863,
        4555775,
        12779510
    ],
    [
        45567,
        16726915,
        14905087
    ],
    [
        16725742,
        9188338,
        10843903
    ],
    [
        6116863,
        16726915,
        14785023
    ],
    [
        8191,
        16726915,
        14905087
    ],
    [
        45567,
        16725742,
        16743805
    ],
    [
        9188338,
        16729383,
        16753546
    ],
    [
        4394426,
        16726915,
        14785023
    ],
    [
        16711784,
        6116863,
        12779510
    ],
    [
        16726915,
        45567,
        14905087
    ],
    [
        4394426,
        16729383,
        16753546
    ],
    [
        16729383,
        9188338,
        14785023
    ],
    [
        16711784,
        16726915,
        12779510
    ],
    [
        6116863,
        16711784,
        16773519
    ],
    [
        8191,
        3723250,
        16754540
    ],
    [
        4394426,
        4394426,
        12779510
    ],
    [
        16725742,
        16729383,
        16745409
    ],
    [
        16725742,
        16726915,
        16745409
    ],
    [
        16729383,
        16726915,
        16743805
    ],
    [
        8191,
        45567,
        16766623
    ],
    [
        16725742,
        16725742,
        16743805
    ],
    [
        45567,
        9188338,
        12779510
    ],
    [
        4394426,
        16726915,
        16773519
    ],
    [
        16729383,
        9188338,
        16766623
    ],
    [
        4394426,
        4555775,
        16754540
    ],
    [
        16711784,
        16711784,
        14905087
    ],
    [
        3723250,
        16725742,
        7763711
    ],
    [
        45567,
        15873618,
        16773519
    ],
    [
        45567,
        16711784,
        16753546
    ],
    [
        6116863,
        16729383,
        16754540
    ],
    [
        8191,
        4555775,
        16753546
    ],
    [
        45567,
        6116863,
        16745409
    ],
    [
        16729383,
        6116863,
        16745409
    ],
    [
        6116863,
        9188338,
        16773519
    ],
    [
        9188338,
        9188338,
        14785023
    ],
    [
        15873618,
        16726915,
        10843903
    ],
    [
        8191,
        4555775,
        16743805
    ],
    [
        8191,
        4394426,
        16773519
    ],
    [
        3723250,
        45567,
        16766623
    ],
    [
        16729383,
        3723250,
        10843903
    ],
    [
        9188338,
        4555775,
        10843903
    ],
    [
        3723250,
        6116863,
        16753546
    ],
    [
        45567,
        16725742,
        16754540
    ],
    [
        16729383,
        15873618,
        16753546
    ],
    [
        15873618,
        16725742,
        16734100
    ],
    [
        16725742,
        45567,
        14905087
    ],
    [
        15873618,
        16711784,
        16766623
    ],
    [
        6116863,
        6116863,
        16743805
    ],
    [
        4394426,
        16729383,
        14905087
    ],
    [
        16729383,
        4394426,
        16745409
    ],
    [
        16725742,
        3723250,
        16754540
    ],
    [
        6116863,
        15873618,
        12779510
    ],
    [
        8191,
        16729383,
        14785023
    ],
    [
        16725742,
        16725742,
        16753546
    ],
    [
        15873618,
        3723250,
        16753546
    ],
    [
        3723250,
        15873618,
        12779510
    ],
    [
        6116863,
        6116863,
        7763711
    ],
    [
        6116863,
        6116863,
        16754540
    ],
    [
        15873618,
        16729383,
        14905087
    ],
    [
        4555775,
        3723250,
        16753546
    ],
    [
        9188338,
        4555775,
        7763711
    ],
    [
        8191,
        45567,
        16773519
    ],
    [
        9188338,
        4394426,
        16753546
    ],
    [
        9188338,
        8191,
        7763711
    ],
    [
        16729383,
        3723250,
        16734100
    ],
    [
        45567,
        4555775,
        16745409
    ],
    [
        45567,
        8191,
        12779510
    ],
    [
        16729383,
        16725742,
        16773519
    ],
    [
        4394426,
        16725742,
        10843903
    ],
    [
        15873618,
        16711784,
        14785023
    ],
    [
        15873618,
        45567,
        16766623
    ],
    [
        8191,
        6116863,
        16745409
    ],
    [
        16725742,
        6116863,
        14905087
    ],
    [
        6116863,
        3723250,
        14785023
    ],
    [
        4394426,
        16725742,
        16743805
    ],
    [
        15873618,
        6116863,
        16745409
    ],
    [
        8191,
        16711784,
        16743805
    ],
    [
        16729383,
        8191,
        12779510
    ],
    [
        16726915,
        16711784,
        14905087
    ],
    [
        6116863,
        4394426,
        16753546
    ],
    [
        8191,
        3723250,
        16766623
    ],
    [
        3723250,
        8191,
        14785023
    ],
    [
        3723250,
        6116863,
        16773519
    ],
    [
        4394426,
        15873618,
        14785023
    ],
    [
        9188338,
        16725742,
        16753546
    ],
    [
        3723250,
        4555775,
        10843903
    ],
    [
        4555775,
        8191,
        16773519
    ],
    [
        4394426,
        16725742,
        7763711
    ],
    [
        15873618,
        6116863,
        16734100
    ],
    [
        16729383,
        9188338,
        16754540
    ],
    [
        16729383,
        3723250,
        16753546
    ],
    [
        8191,
        4394426,
        14785023
    ],
    [
        9188338,
        4555775,
        14785023
    ],
    [
        3723250,
        16726915,
        14785023
    ],
    [
        16711784,
        8191,
        16743805
    ],
    [
        9188338,
        16726915,
        12779510
    ],
    [
        3723250,
        45567,
        16754540
    ],
    [
        16725742,
        15873618,
        16743805
    ],
    [
        16711784,
        4555775,
        16743805
    ],
    [
        4394426,
        3723250,
        16766623
    ],
    [
        45567,
        16711784,
        16773519
    ],
    [
        4394426,
        16729383,
        16734100
    ],
    [
        16726915,
        16726915,
        16734100
    ],
    [
        16726915,
        16711784,
        16745409
    ],
    [
        6116863,
        16726915,
        10843903
    ],
    [
        3723250,
        16711784,
        16766623
    ],
    [
        9188338,
        16725742,
        16745409
    ],
    [
        15873618,
        16711784,
        14905087
    ],
    [
        16726915,
        4555775,
        16754540
    ],
    [
        8191,
        3723250,
        16734100
    ],
    [
        8191,
        9188338,
        10843903
    ],
    [
        9188338,
        45567,
        16743805
    ],
    [
        45567,
        15873618,
        16743805
    ],
    [
        16726915,
        4394426,
        14905087
    ],
    [
        16726915,
        8191,
        12779510
    ],
    [
        16726915,
        16725742,
        14785023
    ],
    [
        16725742,
        16729383,
        14785023
    ],
    [
        3723250,
        4555775,
        16754540
    ],
    [
        45567,
        4394426,
        7763711
    ],
    [
        4555775,
        16726915,
        10843903
    ],
    [
        16725742,
        4555775,
        7763711
    ],
    [
        3723250,
        15873618,
        10843903
    ],
    [
        16711784,
        3723250,
        7763711
    ],
    [
        6116863,
        15873618,
        10843903
    ],
    [
        8191,
        8191,
        16773519
    ],
    [
        15873618,
        4555775,
        16743805
    ],
    [
        8191,
        16725742,
        16766623
    ],
    [
        16725742,
        4555775,
        16754540
    ],
    [
        8191,
        4555775,
        16766623
    ],
    [
        15873618,
        3723250,
        7763711
    ],
    [
        16711784,
        16711784,
        16766623
    ],
    [
        4555775,
        16725742,
        16734100
    ],
    [
        45567,
        16725742,
        12779510
    ],
    [
        9188338,
        4394426,
        12779510
    ],
    [
        4555775,
        4394426,
        16766623
    ],
    [
        4394426,
        6116863,
        16743805
    ],
    [
        16711784,
        16729383,
        16754540
    ],
    [
        16711784,
        3723250,
        16766623
    ],
    [
        4555775,
        8191,
        16753546
    ],
    [
        16726915,
        4394426,
        16734100
    ],
    [
        16711784,
        16725742,
        14785023
    ],
    [
        3723250,
        16725742,
        16753546
    ],
    [
        16725742,
        8191,
        7763711
    ],
    [
        4394426,
        6116863,
        14905087
    ],
    [
        16725742,
        15873618,
        16753546
    ],
    [
        15873618,
        15873618,
        16773519
    ],
    [
        16725742,
        16725742,
        14905087
    ],
    [
        6116863,
        45567,
        7763711
    ],
    [
        16729383,
        45567,
        16766623
    ],
    [
        8191,
        9188338,
        16743805
    ],
    [
        3723250,
        16729383,
        7763711
    ],
    [
        16725742,
        6116863,
        16753546
    ],
    [
        3723250,
        16725742,
        16766623
    ],
    [
        4394426,
        16725742,
        16766623
    ],
    [
        8191,
        16726915,
        16773519
    ],
    [
        4394426,
        6116863,
        14785023
    ],
    [
        4555775,
        16729383,
        16773519
    ],
    [
        16725742,
        8191,
        16734100
    ],
    [
        8191,
        8191,
        14785023
    ],
    [
        16726915,
        15873618,
        16753546
    ],
    [
        4555775,
        16725742,
        10843903
    ],
    [
        16725742,
        9188338,
        12779510
    ],
    [
        15873618,
        9188338,
        16734100
    ],
    [
        4555775,
        9188338,
        16743805
    ],
    [
        45567,
        16726915,
        16754540
    ],
    [
        16726915,
        3723250,
        16766623
    ],
    [
        4394426,
        8191,
        14785023
    ],
    [
        15873618,
        4394426,
        16754540
    ],
    [
        45567,
        16729383,
        16734100
    ],
    [
        16726915,
        16729383,
        16743805
    ],
    [
        45567,
        16726915,
        16753546
    ],
    [
        9188338,
        9188338,
        10843903
    ],
    [
        16711784,
        45567,
        10843903
    ],
    [
        15873618,
        16711784,
        16745409
    ],
    [
        16725742,
        45567,
        10843903
    ],
    [
        45567,
        16725742,
        16753546
    ],
    [
        4394426,
        16726915,
        16754540
    ],
    [
        4394426,
        16711784,
        16766623
    ],
    [
        16726915,
        9188338,
        14905087
    ],
    [
        4555775,
        8191,
        12779510
    ],
    [
        4394426,
        6116863,
        16753546
    ],
    [
        4394426,
        9188338,
        16754540
    ],
    [
        8191,
        16729383,
        16743805
    ],
    [
        9188338,
        4394426,
        16773519
    ],
    [
        16725742,
        16726915,
        14785023
    ],
    [
        4555775,
        45567,
        14905087
    ],
    [
        16726915,
        8191,
        14905087
    ],
    [
        16711784,
        15873618,
        10843903
    ],
    [
        16725742,
        3723250,
        16766623
    ],
    [
        6116863,
        3723250,
        10843903
    ],
    [
        4394426,
        45567,
        10843903
    ],
    [
        15873618,
        45567,
        10843903
    ],
    [
        4394426,
        16729383,
        16743805
    ],
    [
        6116863,
        15873618,
        16743805
    ],
    [
        45567,
        4394426,
        16766623
    ],
    [
        8191,
        15873618,
        7763711
    ],
    [
        45567,
        45567,
        16745409
    ],
    [
        4394426,
        16726915,
        16743805
    ],
    [
        4555775,
        16711784,
        16734100
    ],
    [
        8191,
        16725742,
        14905087
    ],
    [
        16725742,
        16726915,
        16754540
    ],
    [
        16725742,
        16711784,
        16743805
    ],
    [
        16725742,
        6116863,
        16743805
    ],
    [
        15873618,
        16726915,
        16766623
    ],
    [
        9188338,
        15873618,
        16734100
    ],
    [
        8191,
        3723250,
        16773519
    ],
    [
        4555775,
        6116863,
        16753546
    ],
    [
        6116863,
        4555775,
        16773519
    ],
    [
        16729383,
        6116863,
        16734100
    ],
    [
        3723250,
        16725742,
        16754540
    ],
    [
        16726915,
        16726915,
        16773519
    ],
    [
        6116863,
        45567,
        12779510
    ],
    [
        16726915,
        9188338,
        16754540
    ],
    [
        16729383,
        9188338,
        12779510
    ],
    [
        16729383,
        45567,
        16754540
    ],
    [
        15873618,
        9188338,
        16773519
    ],
    [
        16725742,
        16711784,
        14785023
    ],
    [
        6116863,
        4394426,
        12779510
    ],
    [
        16725742,
        6116863,
        7763711
    ],
    [
        4555775,
        16726915,
        12779510
    ],
    [
        3723250,
        16711784,
        14905087
    ],
    [
        4555775,
        4555775,
        16743805
    ],
    [
        16711784,
        16711784,
        16773519
    ],
    [
        16711784,
        15873618,
        12779510
    ],
    [
        16726915,
        15873618,
        16773519
    ],
    [
        4394426,
        3723250,
        14905087
    ],
    [
        15873618,
        45567,
        16773519
    ],
    [
        15873618,
        45567,
        16734100
    ],
    [
        4555775,
        16726915,
        16734100
    ],
    [
        9188338,
        6116863,
        16734100
    ],
    [
        9188338,
        6116863,
        16745409
    ],
    [
        4555775,
        4394426,
        14785023
    ],
    [
        9188338,
        15873618,
        16743805
    ],
    [
        4394426,
        16726915,
        14905087
    ],
    [
        16711784,
        9188338,
        14905087
    ],
    [
        15873618,
        16711784,
        16754540
    ],
    [
        3723250,
        9188338,
        16745409
    ],
    [
        4394426,
        15873618,
        16734100
    ],
    [
        15873618,
        9188338,
        12779510
    ],
    [
        16729383,
        3723250,
        12779510
    ],
    [
        4394426,
        3723250,
        16743805
    ],
    [
        45567,
        16729383,
        16766623
    ],
    [
        9188338,
        4555775,
        16754540
    ],
    [
        15873618,
        9188338,
        16753546
    ],
    [
        45567,
        16711784,
        16754540
    ],
    [
        3723250,
        9188338,
        12779510
    ],
    [
        4555775,
        16729383,
        16753546
    ],
    [
        4555775,
        8191,
        16743805
    ],
    [
        16725742,
        16729383,
        16753546
    ],
    [
        16729383,
        4394426,
        7763711
    ],
    [
        16729383,
        16729383,
        16743805
    ],
    [
        6116863,
        45567,
        16753546
    ],
    [
        16711784,
        4394426,
        16753546
    ],
    [
        15873618,
        8191,
        7763711
    ],
    [
        45567,
        16729383,
        16745409
    ],
    [
        16725742,
        4394426,
        14905087
    ],
    [
        16711784,
        4555775,
        12779510
    ],
    [
        4555775,
        3723250,
        16773519
    ],
    [
        15873618,
        6116863,
        10843903
    ],
    [
        4555775,
        16725742,
        7763711
    ],
    [
        4394426,
        4555775,
        7763711
    ],
    [
        16726915,
        15873618,
        16766623
    ],
    [
        15873618,
        45567,
        14905087
    ],
    [
        16726915,
        16711784,
        16743805
    ],
    [
        16725742,
        16726915,
        16743805
    ],
    [
        45567,
        4555775,
        12779510
    ],
    [
        45567,
        45567,
        16743805
    ],
    [
        8191,
        16726915,
        16754540
    ],
    [
        16729383,
        16726915,
        12779510
    ],
    [
        9188338,
        3723250,
        16734100
    ],
    [
        4555775,
        45567,
        16773519
    ],
    [
        15873618,
        6116863,
        16743805
    ],
    [
        16711784,
        16729383,
        10843903
    ],
    [
        45567,
        4394426,
        16743805
    ],
    [
        3723250,
        9188338,
        16754540
    ],
    [
        6116863,
        16711784,
        10843903
    ],
    [
        16725742,
        4555775,
        16734100
    ],
    [
        45567,
        6116863,
        14905087
    ],
    [
        3723250,
        3723250,
        16745409
    ],
    [
        16726915,
        16729383,
        12779510
    ],
    [
        16726915,
        16711784,
        16753546
    ],
    [
        16726915,
        45567,
        14785023
    ],
    [
        3723250,
        3723250,
        16753546
    ],
    [
        4555775,
        45567,
        16734100
    ],
    [
        16729383,
        16726915,
        16766623
    ],
    [
        16711784,
        16726915,
        16743805
    ],
    [
        16711784,
        16725742,
        14905087
    ],
    [
        16729383,
        16725742,
        14905087
    ],
    [
        16711784,
        8191,
        16734100
    ],
    [
        16726915,
        4394426,
        12779510
    ],
    [
        45567,
        4394426,
        12779510
    ],
    [
        15873618,
        16726915,
        16754540
    ],
    [
        16725742,
        8191,
        10843903
    ],
    [
        4394426,
        4394426,
        16766623
    ],
    [
        16725742,
        16711784,
        10843903
    ],
    [
        6116863,
        3723250,
        16743805
    ],
    [
        16726915,
        16726915,
        16753546
    ],
    [
        16729383,
        16729383,
        10843903
    ],
    [
        9188338,
        16725742,
        16766623
    ],
    [
        4394426,
        8191,
        16734100
    ],
    [
        16711784,
        15873618,
        7763711
    ],
    [
        4394426,
        16725742,
        12779510
    ],
    [
        16725742,
        6116863,
        12779510
    ],
    [
        6116863,
        8191,
        7763711
    ],
    [
        45567,
        45567,
        16734100
    ],
    [
        4394426,
        4394426,
        16773519
    ],
    [
        45567,
        8191,
        14905087
    ],
    [
        15873618,
        16726915,
        16734100
    ],
    [
        16711784,
        9188338,
        16773519
    ],
    [
        16725742,
        9188338,
        16766623
    ],
    [
        16726915,
        16711784,
        10843903
    ],
    [
        3723250,
        16711784,
        16753546
    ],
    [
        16711784,
        4394426,
        16766623
    ],
    [
        9188338,
        45567,
        16773519
    ],
    [
        9188338,
        3723250,
        12779510
    ],
    [
        16725742,
        16729383,
        7763711
    ],
    [
        16726915,
        16729383,
        16745409
    ],
    [
        3723250,
        16725742,
        12779510
    ],
    [
        8191,
        16725742,
        14785023
    ],
    [
        6116863,
        16729383,
        16773519
    ],
    [
        45567,
        16711784,
        16734100
    ],
    [
        16729383,
        8191,
        7763711
    ],
    [
        15873618,
        16729383,
        16773519
    ],
    [
        16711784,
        45567,
        12779510
    ],
    [
        15873618,
        16725742,
        12779510
    ],
    [
        4394426,
        3723250,
        14785023
    ],
    [
        4555775,
        15873618,
        14905087
    ],
    [
        8191,
        3723250,
        16753546
    ],
    [
        9188338,
        3723250,
        16753546
    ],
    [
        15873618,
        16725742,
        16754540
    ],
    [
        3723250,
        16725742,
        16773519
    ],
    [
        16725742,
        3723250,
        14785023
    ],
    [
        6116863,
        16725742,
        14905087
    ],
    [
        16729383,
        16711784,
        16773519
    ],
    [
        6116863,
        8191,
        16773519
    ],
    [
        45567,
        16729383,
        7763711
    ],
    [
        3723250,
        16729383,
        16743805
    ],
    [
        15873618,
        16726915,
        14785023
    ],
    [
        16729383,
        4394426,
        16743805
    ],
    [
        16726915,
        8191,
        16773519
    ],
    [
        45567,
        16725742,
        16745409
    ],
    [
        8191,
        15873618,
        14905087
    ],
    [
        16711784,
        16729383,
        14785023
    ],
    [
        45567,
        15873618,
        16753546
    ],
    [
        3723250,
        3723250,
        12779510
    ],
    [
        8191,
        15873618,
        16734100
    ],
    [
        45567,
        9188338,
        7763711
    ],
    [
        16726915,
        15873618,
        16734100
    ],
    [
        9188338,
        9188338,
        16734100
    ],
    [
        16726915,
        4394426,
        16754540
    ],
    [
        9188338,
        4394426,
        7763711
    ],
    [
        4394426,
        8191,
        12779510
    ],
    [
        45567,
        16725742,
        16734100
    ],
    [
        3723250,
        15873618,
        16745409
    ],
    [
        9188338,
        16726915,
        16773519
    ],
    [
        16729383,
        15873618,
        7763711
    ],
    [
        3723250,
        4394426,
        16753546
    ],
    [
        3723250,
        6116863,
        16743805
    ],
    [
        6116863,
        16729383,
        12779510
    ],
    [
        16711784,
        16726915,
        16766623
    ],
    [
        9188338,
        9188338,
        16766623
    ],
    [
        4555775,
        45567,
        14785023
    ],
    [
        15873618,
        16729383,
        16734100
    ],
    [
        16729383,
        45567,
        16734100
    ],
    [
        16729383,
        4394426,
        16734100
    ],
    [
        8191,
        16729383,
        16766623
    ],
    [
        16729383,
        4394426,
        10843903
    ],
    [
        4555775,
        16726915,
        14905087
    ],
    [
        9188338,
        45567,
        14785023
    ],
    [
        8191,
        8191,
        12779510
    ],
    [
        8191,
        16711784,
        16766623
    ],
    [
        16711784,
        6116863,
        16766623
    ],
    [
        9188338,
        16726915,
        7763711
    ],
    [
        8191,
        16729383,
        16745409
    ],
    [
        16711784,
        9188338,
        12779510
    ],
    [
        45567,
        4394426,
        16753546
    ],
    [
        8191,
        4394426,
        16745409
    ],
    [
        15873618,
        16729383,
        16753546
    ],
    [
        16726915,
        16711784,
        12779510
    ],
    [
        9188338,
        16729383,
        16734100
    ],
    [
        6116863,
        16729383,
        10843903
    ],
    [
        16711784,
        45567,
        14785023
    ],
    [
        45567,
        16726915,
        14785023
    ],
    [
        6116863,
        9188338,
        16734100
    ],
    [
        45567,
        9188338,
        16734100
    ],
    [
        45567,
        8191,
        16754540
    ],
    [
        4555775,
        3723250,
        10843903
    ],
    [
        15873618,
        16726915,
        16743805
    ],
    [
        8191,
        6116863,
        7763711
    ],
    [
        16711784,
        4394426,
        16743805
    ],
    [
        15873618,
        45567,
        12779510
    ],
    [
        3723250,
        15873618,
        16773519
    ],
    [
        16726915,
        9188338,
        7763711
    ],
    [
        4555775,
        4555775,
        16773519
    ],
    [
        16729383,
        16729383,
        12779510
    ],
    [
        16729383,
        16725742,
        16754540
    ],
    [
        45567,
        6116863,
        16773519
    ],
    [
        8191,
        16725742,
        16753546
    ],
    [
        8191,
        15873618,
        16743805
    ],
    [
        8191,
        4555775,
        16745409
    ],
    [
        9188338,
        15873618,
        16754540
    ],
    [
        4555775,
        15873618,
        16754540
    ],
    [
        3723250,
        15873618,
        16753546
    ],
    [
        6116863,
        6116863,
        14905087
    ],
    [
        9188338,
        4394426,
        16734100
    ],
    [
        16726915,
        16711784,
        16766623
    ],
    [
        8191,
        3723250,
        14905087
    ],
    [
        8191,
        9188338,
        16734100
    ],
    [
        16725742,
        45567,
        16766623
    ],
    [
        4555775,
        6116863,
        16766623
    ],
    [
        6116863,
        3723250,
        16773519
    ],
    [
        9188338,
        8191,
        16773519
    ],
    [
        16726915,
        3723250,
        16754540
    ],
    [
        16729383,
        8191,
        14905087
    ],
    [
        9188338,
        16725742,
        7763711
    ],
    [
        3723250,
        6116863,
        16745409
    ]
]

export default colorCombos
