<template>
  <div
    v-if="isVisible"
    class="container-fluid p-4 ps-0 pb-0 pt-2 m-3"
    :style="{
      top: navbarHeight + 'px',
    }"
  >
    <div
      class="col-lg-4 col-md-6 col-sm-6 node-card m-2 p-1 pe-3"
      :style="{
        maxHeight: '70vh',
      }"
    >
      <div class="node-card-inner mt-3 mb-3 pe-3">
        <div class="row m-2 mt-3 mb-4">
          <div class="col-10">
            <h1>{{ selectedNode.page_name }}</h1>
          </div>
          <!-- <div class="col-2">
            <button type="button" @click="clearSelectedNode()" class="btn">
              <p>Hide &#x2196;</p>
            </button>
          </div> -->
        </div>

        <div v-if="selectedNode.content.subtitle" class="row m-2 mt-3 mb-2">
          <div class="col-11">
            <h5>{{ selectedNode.content.subtitle }}</h5>
          </div>
        </div>

        <div class="row pe-3 m-2 mt-2 mb-4">
          <h4>
            {{ selectedNode.content.short_description }}
          </h4>
        </div>

        <div v-if="selectedNode.type != ''" class="row pe-3 m-2 mt-2 mb-0">
          <h5>Type</h5>
        </div>
        <div class="row pe-3 m-2 mb-0 mt-0">
          <h4 class="tag">
            <template v-for="(type, index) in selectedNode.type" :key="type">
              {{ type }}
              <span v-if="index != selectedNode.type.length - 1">
                &#9679;
              </span>
            </template>
          </h4>
        </div>

        <div
          v-if="selectedNode.collection.length > 0"
          class="row pe-3 m-2 mt-2 mb-0"
        >
          <h5>Collection</h5>
        </div>
        <div class="row d-flex pe-3 m-2 mb-0 mt-0">
          <h4 class="tag">
            <template
              v-for="(collection, index) in selectedNode.collection"
              :key="collection"
            >
              {{ collection }}
              <span v-if="index != selectedNode.collection.length - 1">
                &#9679;
              </span>
            </template>
          </h4>
        </div>

        <div
          v-if="selectedNode.author.length > 0"
          class="row pe-3 m-2 mt-2 mb-0"
        >
          <h5>Author</h5>
        </div>
        <div class="row d-flex pe-3 m-2 mb-0 mt-0">
          <h4 class="tag">
            <template
              v-for="(author, index) in selectedNode.author"
              :key="author"
            >
              {{ author }}
              <span v-if="index != selectedNode.author.length - 1">
                &#9679;
              </span>
            </template>
          </h4>
        </div>

        <div
          v-if="selectedNode.content.location.length > 0"
          class="row pe-3 m-2 mt-2 mb-0"
        >
          <h5>Location</h5>
        </div>
        <div class="row d-flex pe-3 m-2 mb-0 mt-0">
          <h4 class="tag">
            <template
              v-for="(location, index) in selectedNode.content.location"
              :key="location"
            >
              {{ location }}
              <span v-if="index != selectedNode.content.location.length - 1">
                &#9679;
              </span>
            </template>
          </h4>
        </div>

        <div
          v-if="selectedNode.content.date != ''"
          class="row pe-3 m-2 mt-2 mb-0"
        >
          <h5>Period</h5>
        </div>
        <div class="row d-flex pe-3 m-2 mb-0 mt-0">
          <h4 class="tag">
            {{ selectedNode.content.date }}
          </h4>
        </div>

        <div class="row pe-3 m-2 mt-3 mb-3 justify-content-start">
          <div class="col ms-0 d-inline-flex">
            <!-- <button type="button" class="btn me-3">
              <a :href="selectedNode.page_url" target="_blank"
                ><p>More info &#8599;</p></a
              >
            </button> -->

            <!-- <button type="button" @click="isVisible = false" class="btn me-3">
              <p>Images &#x2192;</p>
            </button> -->

            <button type="button" @click="clearSelectedNode()" class="btn me-3">
              <p>Hide &#x2196;</p>
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="row ps-3 mt-5">
        <div class="col-3 ps-3">
            <h2>{{selectedNode.date}}</h2>
        </div>
    </div> -->
  </div>
</template>

<script>
export default {
  name: "NodeCard",
  props: [],
  mixins: [],
  data: function () {
    return {
      name: "test",
      width: window.innerWidth,
      height: window.innerHeight,
      isVisible: false,
    };
  },
  methods: {
    clearSelectedNode: function () {
      this.isVisible = false;

      this.$store.commit("set_selected_node", null);
    },
  },
  mounted: function () {
    // console.log(window.innerHeight);
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  unmounted() {
    window.removeEventListener("resize", this.onResize);
  },
  computed: {
    selectedNode() {
      return this.$store.state.selectedNode;
    },
    cssProps: function () {
      return {
        "--width": window.innerWidth,
        "--height": window.innerHeight,
      };
    },
    navbarHeight: function () {
      return this.$store.state.navbarHeight;
    },
  },
  watch: {
    // selectedNode
    selectedNode: function (val) {
      if (val == null) {
        this.isVisible = false;
        return;
      }

      this.isVisible = true;

      this.selectedNode = val;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.container-fluid {
  position: absolute;

  left: 0px;

  color: white;

  z-index: 20000;
  pointer-events: none;
}

.node-card {
  pointer-events: all !important;
  backdrop-filter: blur(20px);

  // border: 1px white solid;
  box-shadow: 0 0 0px 1px white;

  margin: 10px;

  border-radius: 20px;

  padding-bottom: 0px;

  max-height: 95%;
  overflow: hidden;

  display: flex;
  flex-direction: column;
}

.node-card-inner {
  overflow-y: auto;
}

.node-card h1 {
  font-size: 1.953rem !important;

  text-transform: uppercase;

  line-height: 97%;
}

.node-card h3 {
  line-height: 105%;
}

.tag {
  // text-transform: uppercase;
}

.node-card button {
  white-space: nowrap !important;
}

/* width */
::-webkit-scrollbar {
  width: 1px;
  height: 90% !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  height: 90% !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(255, 255, 255);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(255, 255, 255);
}
</style>
