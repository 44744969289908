<template>
  <div>
    <div v-if="!selectedNode && showLabels">
      <div v-for="node in allNodes" :key="node.id">
        <div
          v-if="node.position.z != 0"
          class="title-btn p-2"
          :style="{
            position: 'absolute',
            'z-index': 4000 + parseInt(node.position.z),
            'pointer-events': 'none',
            color: 'white',
            top: node.position.y + 25 + 'px',
            left: node.position.x - 50 + 'px',
            maxWidth: '100px',
            backdropFilter: 'blur(8px)',
          }"
        >
          <h6
            class="d-flex pb-0 mb-0"
            :style="{
              //'font-size': node.position.z + 'px !important',
            }"
          >
            <!-- {{
            node.name.split(" ")[0] +
            " " +
            node.name.split(" ")[1] +
            " " +
            node.name.split(" ")[2]
          }} -->
            {{ node.name }}
            <!-- {{ node.position.z }} -->
          </h6>
        </div>
      </div>
    </div>
    <div v-if="selectedNode">
      <div v-for="node in allNodes" :key="node.id">
        <div
          v-if="node.position.z != 0 && (relatedEntries.includes(node.id) || selectedNode.page_id == node.id)"
          class="title-btn p-2"
          :style="{
            position: 'absolute',
            'z-index': 4000 + parseInt(node.position.z),
            'pointer-events': 'none',
            color: 'white',
            top: node.position.y + 25 + 'px',
            left: node.position.x - 50 + 'px',
            maxWidth: '100px',
            backdropFilter: 'blur(8px)',
          }"
        >
          <h6
            class="d-flex pb-0 mb-0"
            :style="{
              //'font-size': node.position.z + 'px !important',
            }"
          >
            <!-- {{
            node.name.split(" ")[0] +
            " " +
            node.name.split(" ")[1] +
            " " +
            node.name.split(" ")[2]
          }} -->
            {{ node.name }}
            <!-- {{ node.position.z }} -->
          </h6>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src

export default {
  name: "NodeTitle",
  components: {},
  data: function () {
    return {};
  },
  mounted: function () {},
  computed: {
    allNodes: function () {
      return this.$store.state.allNodes;
    },
    exhibitionData: function () {
      return this.$store.state.filteredExhibitionData;
    },
    showLabels: function () {
      return this.$store.state.showLabels;
    },
    selectedNode: function () {
      return this.$store.state.selectedNode;
    },
    relatedEntries: function () {
      return this.$store.state.selectedNode.related_entries;
    },
  },
  watch: {
    // selectedNode: function (val) {
    //   console.log(val);
    //   // console.log(val.related_entries);
    // },
  },
};
</script>

<style lang="scss">
</style>
