<template>
  <div
    class="tooltip-div"
    v-if="width > 768 && hoveredNode"
    :style="{
      pointerEvents: 'all',
      position: 'absolute',
      left: mouseX + 25 + 'px',
      top: mouseY + 25 + 'px',
      zIndex: 5000,
      maxWidth: '20vw',
    }"
  >
    <div class="col m-2 p-3 pb-3 pt-3">
      <div class="row">
        <div class="col-5" v-if="hoveredNodeCoverImage != ''">
          <img :src="hoveredNodeCoverImage" class="tooltip-img" />
        </div>
        <div :class="{ 'col-12': !hasCoverImg, 'col-7': hasCoverImg }">
          <h4 class="pt-1 pb-1">{{ hoveredNode.page_name }}</h4>
          <div v-for="collection in hoveredNode.collection" :key="collection">
            <h5 class="pt-0 pb-0">{{ collection }}</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "TooltipCard",
  props: [],
  mixins: [],
  data: function () {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
      mouseX: -1,
      mouseY: -1,
      testImage: require("@/assets/backgrounds/test/montiel_test.jpg"),
    };
  },
  methods: {
    onResize() {
      this.width = window.innerWidth;
      this.height = window.innerHeight;
    },
    onMouseMove(event) {
      this.mouseX = event.pageX;
      this.mouseY = event.pageY;
    },
  },
  mounted: function () {
    document.addEventListener("mousemove", this.onMouseMove);
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  unmounted() {
    window.removeEventListener("resize", this.onResize);
  },
  computed: {
    cssProps: function () {
      return {
        "--width": window.innerWidth,
        "--height": window.innerHeight,
      };
    },
    hoveredNode: function () {
      return this.$store.state.hoveredNode;
    },
    hoveredNodeCoverImage: function () {
      if (this.hoveredNode.content.cover_image == "") return "";

      // return "data:image/jpeg;base64," + this.hoveredNode.content.cover_image;
      return this.hoveredNode.content.cover_image;
    },
    hasCoverImg: function () {
      return this.hoveredNode.content.cover_image != "";
    }
  },
  watch: {},
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.tooltip-div .col {
  backdrop-filter: blur(20px);

  border: 1px white solid;

  border-radius: 20px;

  padding-bottom: 0px;
}

.tooltip-img {
  width: 100%;
  object-fit: cover;
  height: auto;

  border-radius: 20px;
}
</style>