import { createStore } from "vuex";

export default createStore({
  state: {
    showGui: false,

    navbarDims: [110, 80],
    navbarHeight: 0,

    exhibitionData: [],
    filteredExhibitionData: [],

    // Graph data
    graphData: {},

    cachedGraphData: {},
    notionGraphData: {},

    allNodes: {},
    selectedNode: null,
    hoveredNode: null,

    useNotionGraphData: true,

    // Filters
    showFilters: false,

    // Help / info
    showHelp: false,

    // Labels
    showLabels: false,

    // About
    showAbout: false,

    // Collections
    collections: [],
    selectedCollection: "",

    // Types
    types: [],
    selectedType: "",

    // Authors
    authors: [],
    selectedAuthor: "",

    // Locations
    locations: [],
    selectedLocation: "",

    // Dates
    dates: [],
    selectedDate: "",

    filterItem: "",
  }
  ,
  mutations: {
    set_navbar_height(state, height) {
      state.navbarHeight = height;
    },

    set_graph_data(state, data) {
      state.graphData = JSON.parse(JSON.stringify(data))
    },

    set_notion_graph_data(state, data) {
      state.notionGraphData = JSON.parse(JSON.stringify(data))
    },

    set_cached_graph_data(state, data) {
      state.cachedGraphData = JSON.parse(JSON.stringify(data))
    },

    set_exhibition_data(state, data) {
      state.exhibitionData = JSON.parse(JSON.stringify(data))
    },

    set_selected_node(state, nodeId) {
      state.selectedNode = state.exhibitionData.find(el => el.page_id === nodeId);

      // state.selectedNode = nodeId;

      // console.log(state.selectedNode);
    },

    set_all_nodes(state, nodes) {
      state.allNodes = nodes;
      // console.log(nodes);
    },

    set_hovered_node(state, nodeId) {
      state.hoveredNode = state.exhibitionData.find(el => el.page_id === nodeId);
    },

    toggle_notion_graph_data(state) {
      if (!state.notionGraphData) return;

      state.useNotionGraphData = !state.useNotionGraphData;

      if (state.useNotionGraphData) {
        state.graphData = JSON.parse(JSON.stringify(state.notionGraphData));
      }
      else {
        state.graphData = JSON.parse(JSON.stringify(state.cachedGraphData));
      }
    },

    toggle_filters(state) {
      state.showFilters = !state.showFilters;

      if (!state.showFilters) {
        state.filteredExhibitionData = state.exhibitionData.filter(() => {
          return true;
        })

        state.selectedCollection = "";
        state.selectedType = "";
        state.filterItem = "";
      }
      else {
        state.showHelp = false;
      }
    },

    toggle_help(state) {
      state.showHelp = !state.showHelp;

      if (state.showHelp && state.showFilters) {
        state.showFilters = false;

        state.filteredExhibitionData = state.exhibitionData.filter(() => {
          return true;
        })

        state.selectedCollection = "";
        state.selectedType = "";
      }
    },

    toggle_labels(state) {
      state.showLabels = !state.showLabels;
    },

    toggle_about(state) {
      console.log("toggle about")
      state.showAbout = !state.showAbout;
    },

    set_collections(state, collections) {
      state.collections = collections;
    },

    set_types(state, types) {
      state.types = types;
    },

    set_authors(state, authors) {
      state.authors = authors;
    },

    set_locations(state, locations) {
      state.locations = locations;
    },

    set_dates(state, dates) {
      state.dates = dates;
    },

    get_types(state) {
      return state.types;
    },

    set_selected_item(state, filterData) {

      let item = filterData.clickedItem;
      let filterProperty = filterData.filterProp;

      let selectedKey = "selected" + filterProperty[0].toUpperCase() + filterProperty.substring(1);

      state[selectedKey] = item;

      state.selectedNode = null;
      state.filterItem = item;

      state.filteredExhibitionData = state.exhibitionData.filter((item) => {
        if (item[filterProperty]) {
          return item[filterProperty].includes(state[selectedKey]);
        }
        else {
          return item.content[filterProperty].includes(state[selectedKey]);
        }
      })

      let sources = state.filteredExhibitionData.map(x => x.page_id);

      state.filteredExhibitionData = state.filteredExhibitionData.map(item => {
        item.related_entries = item.related_entries.filter(entry => {
          return (sources.includes(entry))
        })
        return item;
      })

      // console.log(state.filteredExhibitionData)
    },

    set_selected_type(state, type) {
      state.selectedType = type;

      state.selectedNode = null;

      state.filteredExhibitionData = state.exhibitionData.filter((item) => {
        // console.log(item.type[0]);
        // console.log(state.selectedType)
        return item.type.includes(state.selectedType)
      })

      let sources = state.filteredExhibitionData.map(x => x.page_id);

      state.filteredExhibitionData = state.filteredExhibitionData.map(item => {
        item.related_entries = item.related_entries.filter(entry => {
          return (sources.includes(entry))
        })
        return item;
      })

      // console.log(state.filteredExhibitionData)
    },

  },
  actions: {},
  modules: {},
});
