<template>
  <div class="container" :style="cssProps">
    <div id="graph-preview"></div>
  </div>
</template>

<script>
// import * as THREE from "three";
import supershapePreview from "../mixins/graphics/supershape-preview";


export default {
  name: "GraphPreview",
  props: {
    typeVal: Number,
    collectionVal: Number
  },
  mixins: [supershapePreview],
  data: function () {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
    };
  },
  methods: {
    initSketch: function () {
      this.initPreview();
    },
  },
  mounted: function () {
    // Load sketch
      this.initSketch();
      this.animate();
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  unmounted() {
    window.removeEventListener("resize", this.onResize);
  },
  computed: {
    cssProps: function () {
      return {
        "--width": window.innerWidth,
        "--height": window.innerHeight,
      };
    },
  },
  watch: {
    showGui: function () {},
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.container {
  width: 100%;
  // height: 30vh;
  max-height: 30vh;
}

.graph-preview {
  width: 100%;
  height: 100%;
}

</style>
