<template>
  <div class="container" :style="cssProps">
    <div id="graph"></div>
    <div id="datgui"></div>
  </div>
</template>

<script>
// import * as THREE from "three";
import forceGraph from "../mixins/graphics/force-directed-graph";

export default {
  name: "GraphGraphics",
  props: [],
  mixins: [forceGraph],
  data: function () {
    this.camera = null;
    this.scene = null;
    this.renderer = null;
    this.mesh = null;
    return {
      name: "test",
      width: window.innerWidth,
      height: window.innerHeight,
    };
  },
  methods: {
    initSketch: function () {
      this.initGraph();
    },
    animate: function () {
      requestAnimationFrame(this.animate);
      this.updateGraph(this.scene, this.clock, this.Graph);
      if (this.composer) this.composer.render();
    },
    onGraphNodeClicked(nodeId) {
      // console.log(nodeId);

      this.$store.commit("set_selected_node", nodeId);
    },
    onGraphNodeHovered(nodeId) {
      // console.log(nodeId);

      this.$store.commit("set_hovered_node", nodeId);
    },
    onGraphBackgroundClick() {
      this.$store.commit("set_selected_node", "");
    },
    onNoneHovered() {
      // console.log(nodeId);

      this.$store.commit("set_hovered_node", null);
    },
  },
  mounted: function () {
    // Load sketch
      this.initSketch();
      this.animate();
  },
  created() {
    window.addEventListener("resize", this.onResize);
  },
  unmounted() {
    window.removeEventListener("resize", this.onResize);
  },
  computed: {
    showGui() {
      return this.$store.state.showGui;
    },
    cssProps: function () {
      return {
        "--width": window.innerWidth,
        "--height": window.innerHeight,
      };
    },
    graphData() {
      return this.$store.state.graphData;
    },
  },
  watch: {
    showGui: function () {},
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.container {
  z-index: 4000;
  position: absolute;

  top: 0px;
  left: 0px;

  width: 100vw;
  height: 100vh;
}

#datgui {
  position: absolute;
  top: 500px;
  right: -300px;
  z-index: 50000;
}

#graph {
  z-index: 0;
  position: absolute;
  margin: 0px;
  width: var(--width);
  height: var(--height);
}
</style>
