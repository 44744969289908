<template>
  <div class="home" :style="cssProps">
    <div v-if="false">
      <NavbarHome />
    </div>
    <BackgroundGraphicsSimplex />
    <GraphGraphics />
    <div class="jumbotron vertical-center">
      <div class="container-fluid m-2 p-2 align-items-center">
        <div class="row full-height align-items-center">
          <div>
            <h1 class="hero-text">Tracing<br />Queerness</h1>
            <div class="row justify-content-center mt-5 p-0">
              <div class="col-12 align-self-center">
                <button type="button" @click="onEnter()" class="btn">
                  <router-link to="/interface">Enter</router-link>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import GraphGraphics from "@/components/GraphGraphics.vue";
import BackgroundGraphicsSimplex from "@/components/BackgroundGraphicsSimplex.vue";
import NavbarHome from "@/components/NavbarHome.vue";

export default {
  name: "Home",
  components: {
    GraphGraphics,
    BackgroundGraphicsSimplex,
    NavbarHome
  },
  methods: {
    onEnter: function () {
      console.log("enter");
    },
  },
};
</script>

<style lang="scss">
.container-fluid {
  z-index: 50000;
  position: absolute;
  top: 0px;
  left: 0px;

  width: 100vw;
  height: 100vh;

  margin: 0px;
}

.vertical-center {
  min-height: 100%; /* Fallback for browsers do NOT support vh unit */
  min-height: 100vh; /* These two lines are counted as one :-)       */

  display: flex;
  align-items: center;
}

.full-height {
  height: 100vh;
}

.hero-text {
  font-family: "Hatton";
  font-style: normal;
  font-size: 6em !important;
  line-height: 0.9em !important;
  text-transform: uppercase;
  /* or 94% */

  text-align: center !important;
  letter-spacing: -0.08em !important;

  padding-bottom: 0px;
  // margin-bottom: 2vh;

  color: #ffffff;
}
</style>
