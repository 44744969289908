

const simplexFragShader = `
// Author @patriciogv - 2015
// http://patriciogonzalezvivo.com
// From the Book of Shaders
// https://thebookofshaders.com/edit.php#11/lava-lamp.frag


#ifdef GL_ES
precision mediump float;
#endif

// #pragma glslify: grain = require(glsl-film-grain) 

uniform vec2 u_resolution;
uniform vec2 u_mouse;
uniform float u_time;

uniform sampler2D tex;

vec3 mod289(vec3 x) { return x - floor(x * (1.0 / 289.0)) * 289.0; }
vec2 mod289(vec2 x) { return x - floor(x * (1.0 / 289.0)) * 289.0; }
vec3 permute(vec3 x) { return mod289(((x*34.0)+1.0)*x); }

float snoise(vec2 v) {
    const vec4 C = vec4(0.211324865405187,  // (3.0-sqrt(3.0))/6.0
                        0.366025403784439,  // 0.5*(sqrt(3.0)-1.0)
                        -0.577350269189626,  // -1.0 + 2.0 * C.x
                        0.024390243902439); // 1.0 / 41.0
    vec2 i  = floor(v + dot(v, C.yy) );
    vec2 x0 = v -   i + dot(i, C.xx);
    vec2 i1;
    i1 = (x0.x > x0.y) ? vec2(1.0, 0.0) : vec2(0.0, 1.0);
    vec4 x12 = x0.xyxy + C.xxzz;
    x12.xy -= i1;
    i = mod289(i); // Avoid truncation effects in permutation
    vec3 p = permute( permute( i.y + vec3(0.0, i1.y, 1.0 ))
        + i.x + vec3(0.0, i1.x, 1.0 ));

    vec3 m = max(0.5 - vec3(dot(x0,x0), dot(x12.xy,x12.xy), dot(x12.zw,x12.zw)), 0.0);
    m = m*m ;
    m = m*m ;
    vec3 x = 2.0 * fract(p * C.www) - 1.0;
    vec3 h = abs(x) - 0.5;
    vec3 ox = floor(x + 0.5);
    vec3 a0 = x - ox;
    m *= 1.79284291400159 - 0.85373472095314 * ( a0*a0 + h*h );
    vec3 g;
    g.x  = a0.x  * x0.x  + h.x  * x0.y;
    g.yz = a0.yz * x12.xz + h.yz * x12.yw;
    return 130.0 * dot(m, g);
}

void main() {
    vec2 st = gl_FragCoord.xy/u_resolution.xy;
    st.x *= u_resolution.x/u_resolution.y;
    vec3 color = vec3(0.0);

    // vec2 pos = vec2(st*0.5); // noise scale
    vec2 pos = vec2(st*0.7); // noise scale

    vec4 texColor = texture2D(tex, st);

    float DF = 0.0;

    // Add a random position
    float a = 0.0;
    vec2 vel = vec2(u_time*.05);
    DF += snoise(pos+vel)*.25+.25;

    // Add a random position
    a = snoise(pos*vec2(cos(u_time*0.15),sin(u_time*0.1))*0.1)*3.1415;
    vel = vec2(cos(a),sin(a));

    DF += snoise(pos+vel)*.25+.25;

    // color = vec3( smoothstep(.1,.35,fract(DF)) );
    color = vec3( DF + 0.45);
    // gl_FragColor = vec4(1.0-color,1.0);

    // Calculate noise and sample texture
    float mdf = 0.1; // increase for noise amount 
    float noise = (fract(sin(dot(st, vec2(12.9898,78.233)*2.0)) * 43758.5453));
        
    vec3 invColor = 1.0 - color;
    vec3 mixedColor = mix(invColor, texColor.xyz, invColor.x);

    // gl_FragColor = vec4(1.0-color + noise * 0.2,1.0);

    // gl_FragColor = vec4(mixedColor + noise * 0.2,1.0);

    gl_FragColor = vec4(min(mixedColor + noise * 0.15, 1.0), 1.0);

    // gl_FragColor = vec4(invColor, 1.0);


}
`

export default simplexFragShader;