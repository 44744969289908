<template>
  <router-view />
  <TooltipCard />
</template>

<script>
import Notion from "./mixins/data/notion";
// import Navbar from "@/components/Navbar.vue";
import TooltipCard from "@/components/TooltipCard.vue";

export default {
  name: "App",
  mixins: [Notion],
  data: function () {
    return {
      notionFetchInterval: null,
      notionFetchIntervalSeconds: 4 * 60 * 1000,
    };
  },
  components: {
    // Navbar,
    TooltipCard,
  },
  methods: {

  },
  created() {
    this.loadCachedData();

    this.loadNotionData();
    this.notionFetchInterval = setInterval(
      this.loadNotionData,
      this.notionFetchIntervalSeconds
    );
  },
  beforeUnmount() {
    clearInterval(this.notionFetchInterval);
  },
};
</script>


<style lang="scss">
@font-face {
  font-family: "Hatton";
  src: url("~@/assets/fonts/PPHatton/PPHatton-Light.eot");
  src: url("~@/assets/fonts/PPHatton/PPHatton-Light.eot?#iefix")
      format("embedded-opentype"),
    url("~@/assets/fonts/PPHatton/PPHatton-Light.woff2") format("woff2"),
    url("~@/assets/fonts/PPHatton/PPHatton-Light.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "NeueMontreal";
  src: url("~@/assets/fonts/PPNeueMontreal/PPNeueMontreal-Book.eot");
  src: url("~@/assets/fonts/PPNeueMontreal/PPNeueMontreal-Book.eot?#iefix")
      format("embedded-opentype"),
    url("~@/assets/fonts/PPNeueMontreal/PPNeueMontreal-Book.woff2")
      format("woff2"),
    url("~@/assets/fonts/PPNeueMontreal/PPNeueMontreal-Book.woff")
      format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

#app {
  font-family: NeueMontreal, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

html {
  scroll-behavior: smooth;

  overflow: clip;

  font-size: 12px;
  @media (min-width: 800px) {
    font-size: 14px;
  }
  @media (min-width: 1200px) {
    font-size: 16px;
  }
}

h1 {
  font-family: "Hatton", Helvetica, Arial, sans-serif !important;
  font-size: 1.953rem !important;
  text-align: left !important;
}

h2 {
  font-family: "NeueMontreal", Helvetica, Arial, sans-serif !important;
  font-size: 1.563rem !important;
  text-align: left !important;
  line-height: 90%;
}

h3 {
  font-family: "NeueMontreal", Helvetica, Arial, sans-serif !important;
  font-size: 1.25rem !important;
  font-weight: 500 !important;
  text-align: left !important;
  // font-size: 1rem !important;
  // line-height: 90%;

  color: white;
}

h4 {
  font-family: "NeueMontreal", Helvetica, Arial, sans-serif !important;
  font-size: 1rem !important;
  font-weight: 500 !important;
  text-align: left !important;
  // font-size: 1rem !important;

  color: white;
}

h5 {
  font-family: "NeueMontreal", Helvetica, Arial, sans-serif !important;
  font-size: 0.8rem !important;
  font-weight: 500 !important;
  text-align: left !important;
  // font-size: 1rem !important;

  color: white;
  text-transform: uppercase;
}

h6 {
  font-family: "NeueMontreal", Helvetica, Arial, sans-serif !important;
  font-size: 0.5rem !important;
  font-weight: 500 !important;
  // text-align: left !important;
}

.btn-white {
  background-color: white !important;

  color: black !important;
}

.btn-white h3 {
  color: black;
}

.btn-white p {
  color: black;
}

.btn {
  color: white !important;
  background: transparent;

  text-decoration: none;
  text-transform: uppercase;

  padding-left: 30px;
  padding-right: 30px;

  padding-top: 10px;
  padding-bottom: 10px;

  // border: 1.0px solid #ffffff !important;
  box-shadow: 0 0 0px 1px white !important;
  border-radius: 40px !important;

  height: auto;
  width: auto;
}

.title-btn {
  vertical-align: middle;

  color: white !important;
  background: transparent;

  text-decoration: none;
  text-transform: uppercase;

  // border: 1.0px solid #ffffff !important;
  // box-shadow: 0 0 0px 1px white !important;
  border-radius: 20px !important;

  height: auto;
  width: auto;

  margin-bottom: auto;
  margin-top: auto;
}

.btn a {
  color: white;
  text-decoration: none;
}

.btn:hover {
  background-color: white !important;
}

.btn h3:hover {
  color: black !important;
}

.btn a:hover,
.btn p:hover {
  // color: transparent;
  color: black !important;

  // mix-blend-mode: screen;
}

.btn p {
  margin-bottom: 0px !important;
}
</style>
