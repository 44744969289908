<template>
  <div
    v-if="showLabels"
    class="container-fluid info-container w-100 p-4 ps-3 pb-0 pt-2 mt-2 ms-2"
    :style="{
      top: navbarHeight + 'px',
    }"
  >
    <div class="row justify-content-end">
      <div class="col-lg-3 col-md-3 collections-card m-3 p-4 align-self-end">
        <div class="node-card-inner mt-2 mb-1 ms-1">
          <div class="row">
            <div class="col-12">
              <h2 class="hover-underline-animation">
                Move the sliders to explore how the stars are generated.
              </h2>
            </div>
          </div>
          <div class="row">
            <GraphPreview
              :typeVal="typeSliderVal"
              :collectionVal="collectionSliderVal"
            />
          </div>
          <!-- TYPE SLIDER -->
          <div class="row">
            <!-- <VueSlider v-model="modelValue"/> -->
            <h5>Type</h5>
            <h3>{{ types[typeSliderVal] }}</h3>
          </div>
          <div class="row">
            <Slider
              @onSliderChange="onTypeSliderChange"
              minVal="0"
              :maxVal="numTypes - 1"
            />
          </div>

          <!-- COLLECTION SLIDER -->
          <div class="row mt-4">
            <!-- <VueSlider v-model="modelValue"/> -->
            <h5>Collection</h5>
            <h3>{{ collections[collectionSliderVal] }}</h3>
          </div>
          <div class="row">
            <Slider
              @onSliderChange="onCollectionSliderChange"
              minVal="0"
              :maxVal="numCollections - 1"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import GraphPreview from "@/components/GraphPreview.vue";
import Slider from "@/components/Slider.vue";

export default {
  name: "InfoCard",
  props: [],
  mixins: [],
  components: {
    GraphPreview,
    Slider,
  },
  data: function () {
    return {
      width: window.innerWidth,
      height: window.innerHeight,
      typeSliderVal: 0,
      collectionSliderVal: 0,
    };
  },
  methods: {
    onTypeSliderChange(val) {
      this.typeSliderVal = val / 10;
    },
    onCollectionSliderChange(val) {
      this.collectionSliderVal = val / 10;
    },
  },
  mounted: function () {},
  created() {
    window.addEventListener("resize", this.onResize);
  },
  unmounted() {
    window.removeEventListener("resize", this.onResize);
  },
  computed: {
    showLabels() {
      return this.$store.state.showHelp;
    },

    types() {
      return [...this.$store.state.types];
    },

    collections() {
      return [...this.$store.state.collections];
    },

    numCollections() {
      return this.$store.state.collections.size;
    },

    numTypes() {
      return this.$store.state.types.size;
    },

    navbarHeight: function () {
      return this.$store.state.navbarHeight;
    },

    cssProps: function () {
      return {
        "--width": window.innerWidth,
        "--height": window.innerHeight,
      };
    },
  },
  watch: {
    showLabels: function (val) {
      if (!val) {
        this.showLabels = false;
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.container-fluid {
  position: absolute;
  top: 60px;
  left: 0px;

  color: white;

  height: 70vh;

  z-index: 20000;
  pointer-events: none;
}

.collections-card {
  pointer-events: all !important;
  backdrop-filter: blur(20px);

  // border: 1px white solid;
  box-shadow: 0 0 0px 1px white;

  margin: 10px;

  border-radius: 20px;

  padding-bottom: 0px;

  max-height: 70vh !important;
  overflow: hidden;

  display: flex;
  flex-direction: column;
}

.node-card-inner {
  overflow-y: auto;
}

/* width */
::-webkit-scrollbar {
  width: 1px;
  height: 90% !important;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  height: 90% !important;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(255, 255, 255);
}
</style>